import { handleBackendError, omniApiConfiguration, wait } from '@services';
import { ListMetaInfo, ListParams } from 'interfaces';
import {
  ManagerList,
  OrganizationData,
  OrganizationDataItem
} from './dataTypes';
import {
  OldLmsMigratorApi,
  OrganizationListSortOptionsDTOFieldNameEnum,
  OrganizationListSortOptionsDTOOrderEnum,
  OrganizationManagerSelectItemListDTOResponse,
  OrganizationsListPageApi
} from '@xq/omni-gateway-frontend-client';

export interface OrganizationsService {
  fetchOrganizations(params: ListParams): Promise<OrganizationData>;

  fetchManagers(): Promise<ManagerList[]>;

  setOrganizationManager(
    organizationId: string,
    newManagerId: string
  ): Promise<void>;

  migrateOrganization(organizationId: string): Promise<void>;
}

const omniOrganizationsGateway = new OrganizationsListPageApi(
  omniApiConfiguration
);

const migrateGateway = new OldLmsMigratorApi(omniApiConfiguration);

export class OrganizationsServiceApi implements OrganizationsService {
  async fetchOrganizations(params: ListParams): Promise<OrganizationData> {
    try {
      return await omniOrganizationsGateway.organizationListPageControllerGetOrganizationList(
        {
          searchString: params?.searchValue,
          organizationListOptionsDTORequest: {
            pagination: {
              page: params?.page,
              limit: params?.limit
            },
            filters: [],
            sort: [
              {
                order:
                  params?.sortOrder as OrganizationListSortOptionsDTOOrderEnum,
                fieldName:
                  params?.sortBy as OrganizationListSortOptionsDTOFieldNameEnum
              }
            ]
          }
        }
      );
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchOrganizations.name
      );
    }
  }

  async fetchManagers(): Promise<ManagerList[]> {
    try {
      const res: OrganizationManagerSelectItemListDTOResponse =
        await omniOrganizationsGateway.organizationListPageControllerGetOrganizationManagerList();
      return res.items;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchManagers.name
      );
    }
  }

  async setOrganizationManager(
    organizationId: string,
    newManagerId: string
  ): Promise<void> {
    try {
      await omniOrganizationsGateway.organizationListPageControllerSetOrganizationManager(
        {
          organizationManagerDTORequest: {
            managerUuid: newManagerId,
            organizationUuid: organizationId
          }
        }
      );
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.setOrganizationManager.name
      );
    }
  }

  async migrateOrganization(organizationId: string): Promise<void> {
    try {
      await migrateGateway.oldLmsMigratorControllerMigrateOrganization({
        organizationUuid: organizationId
      });
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.setOrganizationManager.name
      );
    }
  }
}

const managersListMock: ManagerList[] = [
  { label: 'Markus Asikainen', value: '1' },
  { label: 'Nikita Pakhomov', value: '2' },
  { label: 'IM IM', value: '3' },
  { label: 'Alexander Tereschenko', value: '4' }
];

const organizationMetaInfoMock: ListMetaInfo = {
  totalItems: 50,
  totalPages: 5,
  itemsPerPage: 10,
  currentPage: 2,
  itemCount: 50
};

const organizationsListMock: OrganizationDataItem[] = [
  {
    uuid: '1',
    name: '2NS',
    countryIso3: 'FI',
    createdAt: new Date('2019-09-24T08:00:00.000Z'),
    usersAmount: 24,
    currencyIso3: 'EUR',
    managerUuid: '1',
    licenses: [
      { name: 'Fundy', isTrial: false, isExpired: false, isSuspended: false },
      {
        name: 'ModelTree: Pro',
        isTrial: false,
        isExpired: false,
        isSuspended: false
      }
    ],
    description: 'Description',
    mtDatabaseVersion: '999.000.000',
    modeltreeInfo: {
      errorDetails: 'Error details',
      needToUpdate: false
    }
  },
  {
    uuid: '2',
    name: 'A.N.D. Corporation',
    countryIso3: 'GB',
    createdAt: new Date('2016-01-20T08:00:00.000Z'),
    usersAmount: 41,
    currencyIso3: 'EUR',
    managerUuid: '2',
    licenses: [
      {
        name: 'ModelTree: Investment management',
        isTrial: false,
        isExpired: false,
        isSuspended: true
      }
    ],
    description: 'FI',
    mtDatabaseVersion: '56.465.236',
    modeltreeInfo: {
      errorDetails: 'Error details',
      needToUpdate: false
    }
  },
  {
    uuid: '3',
    name: 'Aareal Bank',
    countryIso3: 'DE',
    createdAt: new Date('2020-09-11T08:00:00.000Z'),
    usersAmount: 9,
    currencyIso3: 'EUR',
    managerUuid: '3',
    licenses: [
      {
        name: 'Fundy',
        isTrial: false,
        isExpired: false,
        isSuspended: false
      },
      {
        name: 'ModelTree: Valuation',
        isTrial: false,
        isExpired: false,
        isSuspended: false
      }
    ],
    description: 'DE',
    modeltreeInfo: {
      errorDetails: 'Error details',
      needToUpdate: false
    }
  },
  {
    uuid: '4',
    name: 'ABN Consult',
    countryIso3: 'RU',
    createdAt: new Date('2018-11-30T08:00:00.000Z'),
    usersAmount: 37,
    currencyIso3: 'EUR',
    managerUuid: '1',
    licenses: [
      {
        name: 'ModelTree: Valuation',
        isTrial: true,
        isExpired: true,
        isSuspended: false
      }
    ],
    mtDatabaseVersion: '875.142.12',
    modeltreeInfo: {
      errorDetails: 'Error details',
      needToUpdate: false
    }
  },
  {
    uuid: '5',
    name: 'Accent Investment',
    countryIso3: 'GB',
    createdAt: new Date('2021-04-07T08:00:00.000Z'),
    usersAmount: 2,
    currencyIso3: 'EUR',
    managerUuid: '2',
    licenses: [
      { name: 'Fundy', isTrial: false, isExpired: false, isSuspended: false }
    ],
    mtDatabaseVersion: '678.23.105',
    modeltreeInfo: {
      errorDetails: 'Error details',
      needToUpdate: false
    }
  },
  {
    uuid: '6',
    name: 'Adelaer',
    countryIso3: 'AE',
    createdAt: new Date('2019-05-10T08:00:00.000Z'),
    usersAmount: 49,
    currencyIso3: 'EUR',
    managerUuid: '4',
    licenses: [
      {
        name: 'ModelTree: Pro',
        isTrial: false,
        isExpired: false,
        isSuspended: true
      }
    ],
    modeltreeInfo: {
      errorDetails: 'Error details',
      needToUpdate: false
    }
  },
  {
    uuid: '7',
    name: 'Aktia Varainhoito',
    countryIso3: 'RU',
    createdAt: new Date('2021-09-23T08:00:00.000Z'),
    usersAmount: 1200,
    currencyIso3: 'EUR',
    managerUuid: '2',
    licenses: [
      {
        name: 'ModelTree: Investment management',
        isTrial: true,
        isExpired: false,
        isSuspended: false
      }
    ],
    description: 'RU',
    mtDatabaseVersion: '32.543.568',
    modeltreeInfo: {
      errorDetails: 'Error details',
      needToUpdate: false
    }
  },
  {
    uuid: '8',
    name: 'Keskinäinen Työeläkevakuutusyht iö Elo',
    countryIso3: 'FI',
    createdAt: new Date('2010-01-17T08:00:00.000Z'),
    usersAmount: 1,
    currencyIso3: 'EUR',
    managerUuid: '4',
    licenses: [
      {
        name: 'ModelTree: Valuation',
        isTrial: false,
        isExpired: false,
        isSuspended: false
      }
    ],
    description: 'Description',
    mtDatabaseVersion: '93.450.146',
    modeltreeInfo: {
      errorDetails: 'Error details',
      needToUpdate: false
    }
  },
  {
    uuid: '9',
    name: 'Art-Invest Real Estate Management',
    countryIso3: 'GB',
    createdAt: new Date('2018-01-11T08:00:00.000Z'),
    usersAmount: 8,
    currencyIso3: 'RUB',
    managerUuid: '2',
    licenses: [],
    description: 'Citrix Test',
    mtDatabaseVersion: '6.034.357',
    modeltreeInfo: {
      errorDetails: 'Error details',
      needToUpdate: false
    }
  }
];

const organizationsDataMock: OrganizationData = {
  meta: organizationMetaInfoMock,
  items: organizationsListMock
};

export class OrganizationsServiceMock implements OrganizationsService {
  async fetchOrganizations(params: ListParams): Promise<OrganizationData> {
    try {
      await wait(1000);
      return organizationsDataMock;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchOrganizations.name
      );
    }
  }

  async fetchManagers(): Promise<ManagerList[]> {
    try {
      await wait(1000);
      return [...managersListMock];
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchManagers.name
      );
    }
  }

  async setOrganizationManager(
    organizationId: string,
    newManagerId: string
  ): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.setOrganizationManager.name
      );
    }
  }

  async migrateOrganization(organizationId: string): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.setOrganizationManager.name
      );
    }
  }
}
