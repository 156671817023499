import { Icon } from '@components/Icon';
import cn from 'classnames';
import React, { FC, useState } from 'react';
import styles from './DataOnMapControl.module.scss';
import { Checkbox } from '@components/Checkbox';

export interface DataOnMapControlProps {
  className?: string;
  mapControlItems: { showSubmarketNames: boolean; showKPIValue: boolean };
  onChange: (value: string, state: boolean) => void;
}

export const DataOnMapControl: FC<DataOnMapControlProps> = ({
  className,
  mapControlItems,
  onChange
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (value: string, state: boolean) => {
    onChange(value, state);
  };

  return (
    <>
      <div
        className={cn(styles.wrapper, className, { [styles.active]: isOpen })}
        onClick={handleClick}
      >
        <Icon name="slider" size="s" />
      </div>
      {isOpen && (
        <div className={styles.modal}>
          <p className={styles.title}>Data on map</p>
          <div className={styles.modalContent}>
            <Checkbox
              label="Show submarket names"
              checked={mapControlItems.showSubmarketNames}
              onChange={(state) =>
                handleCheckboxChange('showSubmarketNames', state)
              }
            />
            <Checkbox
              label="Show KPI value"
              checked={mapControlItems.showKPIValue}
              onChange={(state) => handleCheckboxChange('showKPIValue', state)}
            />
          </div>
        </div>
      )}
    </>
  );
};
