import {
  ComponentProps,
  Icon,
  SkeletonPlaceholder,
  TableAction,
  cellStyles
} from '@components';
import { TableColumn } from '@components/Table/dataTypes';
import cn from 'classnames';
import React, { FC } from 'react';
import styles from './TableRowSkeleton.module.scss';

export interface TableRowSkeletonProps extends ComponentProps {
  columns: TableColumn[];
  actions?: TableAction[];
  actionColumn?: TableColumn;
  hasPlusActionColumn?: boolean;
}

export const TableRowSkeleton: FC<TableRowSkeletonProps> = (props) => {
  const {
    columns,
    actions,
    actionColumn,
    hasPlusActionColumn,
    id,
    className,
    style
  } = props;

  return (
    <tr id={id} style={style} className={cn(className, styles.row)}>
      {columns?.map((column, key) => {
        return (
          <React.Fragment key={column.key + '_fragment'}>
            <td valign="middle" style={cellStyles({ column })} key={column.key}>
              <div style={{ width: column?.width + 'px' || 'auto' }}>
                <SkeletonPlaceholder width={column?.width} height={12} />
              </div>
            </td>
            {hasPlusActionColumn && key === 0 && (
              <td
                valign="middle"
                style={cellStyles({ column })}
                key={column.key + '_select'}
              >
                <div style={{ width: column?.width + 'px' || 'auto' }}>
                  <SkeletonPlaceholder width={column?.width} height={12} />
                </div>
              </td>
            )}
          </React.Fragment>
        );
      })}
      {actions && actionColumn && (
        <td valign="middle" className={styles.actions} key={'column_action'}>
          <Icon
            style={cellStyles({ column: actionColumn })}
            className={styles.icon}
            name={actions?.length > 1 ? 'more' : actions[0]?.icon}
            size={actions?.length > 1 ? 'm' : 's'}
          ></Icon>
        </td>
      )}
    </tr>
  );
};

TableRowSkeleton.displayName = 'TableRowSkeleton';
