import React, { FC, MouseEvent, useEffect, useState } from 'react';
import { ComponentProps, Icon, MenuItem, Typography } from '@components';
import cn from 'classnames';
import styles from './Sidemenu.module.scss';
import { offsets } from '@componentsStyles';
import { useCurrentWidth } from '../../hooks';
import { NavLinkProps } from 'react-router-dom';
import { logger } from '@componentsUtils';

export interface SidemenuProps extends ComponentProps {
  items: MenuItem[];
  NavLink: React.ForwardRefExoticComponent<
    NavLinkProps & React.RefAttributes<HTMLAnchorElement>
  >;
  activeMenuKey: string;

  contentClassName?: string;
  setActiveMenuKey: (key: string) => void;
}

export const Sidemenu: FC<SidemenuProps> = (props) => {
  const {
    id,
    className,
    style,
    dataTestId,
    items,
    activeMenuKey,
    setActiveMenuKey,
    contentClassName,
    NavLink
  } = props;

  const { breakpoint } = useCurrentWidth();

  const isLg = breakpoint === 'lg';
  const isSm = breakpoint === 'sm' || breakpoint === 'xs';

  const [showMenus, setShowMenus] = useState<boolean>(!isSm);

  const sidebarWidth = () => {
    if (showMenus) {
      return isLg ? '240px' : '200px';
    }
    return isLg ? '100px' : '60px';
  };

  const navItemPadding = isLg ? '15px 40px' : '15px 20px';
  const navSubItemPadding = isLg ? '68px' : '48px';

  const setActiveItem = (event: MouseEvent, key: string) => {
    event.stopPropagation();
    if (setActiveMenuKey) {
      setActiveMenuKey(key);
    } else {
      logger.error('setActiveMenuKey function is not provided');
    }
  };

  const [activeMenu, setActiveMenu] = useState<string>(null);
  const [activeSubmenu, setActiveSubmenu] = useState<string>(null);

  const calculateActiveMenus = (key: string) => {
    const activeMenu: MenuItem = items?.find((item) => item.key == key);

    if (activeMenu) {
      setActiveMenu(key);
      setActiveSubmenu(null);
      return;
    }

    items
      ?.filter((item) => item.sideMenus)
      ?.forEach((item) => {
        const sidemenu = item.sideMenus.find(
          (sidemenu) => sidemenu.key === key
        );
        if (sidemenu) {
          setActiveMenu(item.key);
          setActiveSubmenu(sidemenu.key);
          return;
        }
      });
  };

  useEffect(() => {
    calculateActiveMenus(activeMenuKey);
  }, [activeMenuKey, items]);

  const sidemenuClassname = (key: string) => {
    if (!showMenus) {
      return styles['submenu-item--hidden'];
    }
    if (key === activeSubmenu) {
      return styles['submenu-item--selected'];
    }
    return styles['submenu-item'];
  };

  const menuItemClassname = (key: string) => {
    if (activeMenu !== key) {
      return styles['nav-item'];
    }
    if (!activeSubmenu) {
      return styles['nav-item--selected'];
    }
    if (showMenus) {
      return styles['nav-item--subselected'];
    }
    return styles['nav-item--subselected--closed'];
  };

  return (
    <div
      id={id}
      data-testid={dataTestId}
      style={{ ...style, width: sidebarWidth() }}
      className={cn(className, styles.wrapper)}
    >
      <div id={'sidebar'} className={cn(styles.sidebar, contentClassName)}>
        <button
          className={styles.button}
          onClick={() => setShowMenus(!showMenus)}
        >
          <Icon
            name={showMenus ? 'chevron-left' : 'chevron-right'}
            size={'m'}
          />
        </button>

        <div id="sidemenu-nav-items" className={styles['nav-items']}>
          {items?.map((item) => {
            return (
              <div key={item.key} onClick={(e) => setActiveItem(e, item.key)}>
                <NavLink
                  to={item.route}
                  style={{ padding: navItemPadding }}
                  className={menuItemClassname(item.key)}
                >
                  <Icon
                    size={'m'}
                    className={offsets['mr-8']}
                    name={item.icon}
                  />

                  <div
                    className={
                      showMenus
                        ? styles['nav-item-title']
                        : styles['nav-item-title--hidden']
                    }
                  >
                    <Typography
                      variant="body-5"
                      className={styles.title}
                      element="div"
                    >
                      {item.title}
                    </Typography>

                    {item.warningIcon && showMenus && (
                      <Icon name={item.warningIcon} size="s" color="error" />
                    )}
                  </div>
                </NavLink>

                {item?.sideMenus && (
                  <div className={styles.sidemenus}>
                    {item.sideMenus.map((sidemenu) => {
                      return (
                        <NavLink
                          key={sidemenu.key}
                          to={sidemenu.route}
                          style={{ paddingLeft: navSubItemPadding }}
                          onClick={(e) => setActiveItem(e, sidemenu.key)}
                          className={cn(sidemenuClassname(sidemenu.key))}
                        >
                          <Typography variant="body-4" element="div">
                            {sidemenu.title}
                          </Typography>
                          {sidemenu.warningIcon && (
                            <Icon
                              name={sidemenu.warningIcon}
                              size="s"
                              color="error"
                            />
                          )}
                        </NavLink>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

Sidemenu.displayName = 'Sidemenu';
