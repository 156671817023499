import React, {
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import styles from './ActivityLog.module.scss';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams, useSearchParams } from 'react-router-dom';
import {
  Breadcrumbs,
  BreadcrumbsItem,
  Button,
  changeSortDirection,
  Col,
  DateIntervalType,
  DatePicker,
  EmptyState,
  offsets,
  openStatusNotification,
  Row,
  Search,
  SortDirection,
  Table,
  TableColumn,
  Typography
} from '@xq/ui-kit';
import {
  ActivityLogService,
  ActivityLogServiceApi
} from './activity-log-service';
import {
  checkEmptyState,
  checkNoMoreData,
  debounce,
  downloadFile,
  getBreadcrumbWithDropdown,
  getCurrentLanguage,
  getStatusNotificationTranslations,
  ORGANIZATION_SIDEMENUS
} from '@services';
import { SidemenuContext, SidemenuContextData } from '@context';
import { getRouteUrl, ROUTES } from '@router';
import { ListMetaInfo, ListParams } from 'interfaces';
import { SORT_DIRECTIONS } from '@constants';
import { createActivityLogsRows, createInvoiceDetailsColumns } from './utils';
import { ActivityLogItem } from '@pages/Organizations/ActivityLog/dataTypes';
import { useDebounce } from '@hooks';
import { config } from '@config';

export const ActivityLog: FC = () => {
  const service: ActivityLogService = new ActivityLogServiceApi();
  const sidemenuContext: SidemenuContextData = useContext(SidemenuContext);
  const { t } = useTranslation();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  /** Tables and base information  */
  const [activityLogs, setActivityLogs] = useState<ActivityLogItem[]>([]);
  const [activityLogsMeta, setActivityLogsMeta] = useState<ListMetaInfo>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFileDownloading, setIsFileDownloading] = useState<boolean>(false);
  const [noResults, setNoResults] = useState<boolean>(false);
  const [sortOrder, setSortOrder] = useState<SortDirection>(
    SORT_DIRECTIONS.DESC as SortDirection
  );
  const [sortBy, setSortBy] = useState<string>('timestamp');
  const [noMoreData, setNoMoreData] = useState<boolean>(false);
  const [rangePickerValue, setRangePickerValue] = useState<[Date, Date]>(null);
  const [formattedRangePickerValue, setFormattedRangePickerValue] =
    useState<[Date, Date]>(null);

  const [searchValue, setSearchValue] = useState<string>(
    searchParams.get('search') || ''
  );
  const debouncedSearchValue = useDebounce(searchValue, config.debounceDelay);
  const [isEmptyState, setIsEmptyState] = useState<boolean>(false);

  const activityLogsColumns: TableColumn[] = createInvoiceDetailsColumns(t);

  const activityLogsRows = useMemo(() => {
    return createActivityLogsRows(t, activityLogs);
  }, [activityLogs]);

  function formatDatesInterval(dates: [Date, Date]): [Date, Date] {
    const [startDate, endDate] = dates;
    const startOfDay = new Date(startDate.setHours(0, 0, 0, 0));
    const endOfDay = new Date(endDate.setHours(23, 59, 59, 999));

    const formattedValue: [Date, Date] = [startOfDay, endOfDay];
    return formattedValue;
  }

  function selectRangePickerValue(dates: [Date, Date]) {
    setRangePickerValue(dates);

    if (!dates) {
      setFormattedRangePickerValue(null);
      return;
    }

    const formattedValue: [Date, Date] = formatDatesInterval(dates);
    setFormattedRangePickerValue(formattedValue);
  }

  async function fetchActivityLogs(queryParams?: ListParams) {
    setIsLoading(true);
    try {
      const response = await service.fetchActivityLogs(
        params?.id,
        formattedRangePickerValue,
        queryParams
      );

      const hasResults = !response.items || response?.items?.length === 0;
      setNoResults(hasResults);
      setActivityLogs(response?.items);
      checkNoMoreData(
        response?.items?.length,
        response?.meta?.totalItems,
        setNoMoreData
      );
      setActivityLogsMeta(response?.meta);
      checkEmptyState({
        setIsEmptyState,
        meta: response?.meta,
        rangePickerValue: formattedRangePickerValue,
        searchValue
      });
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function downloadActivityLogs() {
    try {
      if (!formattedRangePickerValue) {
        openStatusNotification({
          translations: getStatusNotificationTranslations(t),
          status: 400,
          message: t('notifications.dateRangeMustBeSpecifiedForExport')
        });
        return;
      }
      setIsFileDownloading(true);
      const csvData = await service.downloadActivityLogs(
        params.id,
        [formattedRangePickerValue?.[0], formattedRangePickerValue?.[1]],
        searchValue
      );
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      await downloadFile(url);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsFileDownloading(false);
    }
  }

  function updateSortParams(key: string) {
    let updatedSortBy = sortBy;
    let updatedSortOrder = sortOrder;

    if (key === sortBy) {
      updatedSortOrder = changeSortDirection(sortOrder);
      setSortOrder(updatedSortOrder);
    } else {
      updatedSortBy = key;
      setSortBy(updatedSortBy);
    }
  }

  async function loadMoreActivityLogs() {
    try {
      if (noMoreData) {
        return;
      }

      const response = await service.fetchActivityLogs(
        params.id,
        formattedRangePickerValue,
        {
          page: ++activityLogsMeta.currentPage,
          searchValue: debouncedSearchValue,
          sortOrder: sortOrder,
          sortBy: sortBy
        }
      );

      const { items } = response;
      checkNoMoreData(items?.length, response?.meta?.totalItems, setNoMoreData);
      setActivityLogs([...activityLogs, ...items]);
      setActivityLogsMeta(response?.meta);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  useEffect(() => {
    fetchActivityLogs({
      searchValue: debouncedSearchValue,
      sortOrder: sortOrder,
      sortBy: sortBy
    });
  }, [
    params.id,
    sortOrder,
    sortBy,
    debouncedSearchValue,
    formattedRangePickerValue
  ]);

  const updateSearchParams = useCallback(
    debounce((newValue) => {
      if (newValue) {
        searchParams.set('search', newValue);
      } else {
        searchParams.delete('search');
      }
      setSearchParams(searchParams);
    }, config.debounceDelay),
    [searchParams, setSearchParams]
  );

  useEffect(() => {
    const searchParamValue = searchParams.get('search') || '';
    setSearchValue(searchParamValue);
  }, [searchParams]);

  const handleSearchValue = (newValue: string) => {
    setSearchValue(newValue);
    updateSearchParams(newValue);
  };

  useEffect(() => {
    setIsEmptyState(false);
  }, [params.id]);

  /** Sidemenu */
  useEffect(() => {
    sidemenuContext.setActiveMenu(
      ORGANIZATION_SIDEMENUS.ORGANIZATION_ACTIVITY_LOG
    );
  }, [sidemenuContext]);

  /** Breadcrumbs */
  const breadcrumbs: BreadcrumbsItem[] = useMemo(
    () => [
      {
        label: t(ROUTES.ORGANIZATIONS.MAIN),
        url: getRouteUrl(ROUTES.ORGANIZATIONS.MAIN)
      },
      getBreadcrumbWithDropdown(
        t,
        sidemenuContext,
        ROUTES.ORGANIZATIONS.ACTIVITY_LOGS,
        { id: params?.id }
      )
    ],
    [sidemenuContext, params.id]
  );

  return (
    <Fragment>
      <Row cols={10}>
        <Breadcrumbs
          NavLink={NavLink}
          className={'breadcrumbs'}
          items={breadcrumbs}
        />
        <Col col={9} className={'heading'}>
          <Typography element="div" variant="h2">
            {t('routes.organizations.activityLogs')}
          </Typography>

          {!isEmptyState && (
            <div className={styles.buttons}>
              <Button
                type="third"
                icon="download"
                onClick={downloadActivityLogs}
                isLoading={isFileDownloading}
              >
                {t('common.export')}
              </Button>
            </div>
          )}
        </Col>
      </Row>

      <Row cols={10} style={{ marginRight: 0 }}>
        {!isLoading && isEmptyState && (
          <EmptyState
            heading={t('uiKit.oopsItIsEmpty')}
            description={t('alerts.looksLikeNoOneCame')}
            isCentered={true}
          />
        )}
      </Row>

      {!isEmptyState && (
        <Row cols={10}>
          <Col col={9} md={6} lg={4}>
            <div className={styles['search-wrap']}>
              <DatePicker
                locale={getCurrentLanguage()}
                type="range"
                rangePickerValue={rangePickerValue}
                disabled={isLoading}
                onRangePickerChange={selectRangePickerValue}
                dateIntervalType={DateIntervalType.Date}
                rangePickerPlaceholder={'dd-mm-year'}
              />

              <div>
                <Search
                  placeholder={t('organizations.searchByUser')}
                  value={searchValue}
                  onChange={handleSearchValue}
                  disabled={isLoading}
                />
              </div>
            </div>
          </Col>

          <Col col={9}>
            <Table
              isLoading={isLoading}
              className={offsets['mb-40']}
              rows={activityLogsRows}
              columns={activityLogsColumns}
              noMoreData={noMoreData}
              noMoreItemsText={t('common.noMoreItems')}
              onLoading={loadMoreActivityLogs}
              maxHeight="640px"
              minWidth={900}
              onSort={updateSortParams}
              sortOrder={sortOrder}
              sortBy={sortBy}
              noResults={noResults}
              noResultsText={t('common.noResults')}
            />
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

ActivityLog.displayName = 'ActivityLog';
