'use client';
import React, { AnchorHTMLAttributes, forwardRef, useMemo } from 'react';
import { DateIntervalType, DatePickerType, Locales } from './dataTypes';
import { ComponentProps } from '@components/dataTypes';
import { Icon } from '@components';
import classNames from 'classnames';
import { default as AntDatePicker } from 'antd/lib/date-picker';
import ConfigProvider from 'antd/lib/config-provider';
import styles from './DatePicker.module.scss';
import enGB from 'antd/locale/en_GB';
import ruRU from 'antd/locale/ru_RU';
import fiFI from 'antd/locale/fi_FI';
import 'dayjs/locale/ru.js';
import 'dayjs/locale/en.js';
import 'dayjs/locale/fi.js';
import { colors } from '@componentsStyles';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

export interface DatePickerProps
  extends ComponentProps,
    AnchorHTMLAttributes<HTMLElement> {
  dateIntervalType: DateIntervalType;
  type: DatePickerType;
  locale?: Locales;
  datePickerPlaceholder?: string;
  rangePickerPlaceholder?: string;
  datePickerValue?: Date;
  rangePickerValue?: [Date, Date];
  disabled?: boolean;
  disablePrevDates?: boolean;
  onDatePickerChange?: (date: Date) => void;
  onRangePickerChange?: (dates: [Date, Date]) => void;
  isPopupFixed?: boolean;
  isUtc?: boolean;
}

export type DatePickerRef = HTMLDivElement;

export const DatePicker = forwardRef<DatePickerRef, DatePickerProps>(
  (props, ref) => {
    const {
      type = 'day',
      className,
      id,
      style,
      dataTestId,
      datePickerPlaceholder = 'Select date',
      rangePickerPlaceholder = 'mm-year',
      datePickerValue,
      rangePickerValue,
      locale = 'en',
      disabled,
      onDatePickerChange,
      onRangePickerChange,
      disablePrevDates,
      dateIntervalType = DateIntervalType.Month,
      isPopupFixed,
      isUtc
    } = props;

    const { RangePicker } = AntDatePicker;

    dayjs.extend(utc);

    const shownDatePickerValue = useMemo(() => {
      if (!datePickerValue) {
        return null;
      }
      if (isUtc) {
        return dayjs(datePickerValue).utc();
      }
      return dayjs(datePickerValue);
    }, [datePickerValue]);

    const onDatePickerChangeFunction = (date: Dayjs, dateString: string) => {
      if (!onDatePickerChange) {
        return;
      }

      if (!date) {
        onDatePickerChange(null);
        return;
      }
      const startOfDay = date.startOf('day');

      if (!isUtc) {
        onDatePickerChange(startOfDay?.toDate());
        return;
      }

      onDatePickerChange(startOfDay?.utc()?.toDate());
    };

    const onRangePickerChangeFunction = (
      dates: [Dayjs, Dayjs],
      dateStrings: [string, string]
    ) => {
      if (!onRangePickerChange) {
        return;
      }
      onRangePickerChange(
        dates === null ? null : [dates[0].toDate(), dates[1].toDate()]
      );
    };

    const getLang = () => {
      switch (locale) {
        case 'en': {
          return enGB;
        }
        case 'ru': {
          return ruRU;
        }
        case 'fi': {
          return fiFI;
        }
        default: {
          return enGB;
        }
      }
    };

    const lang = useMemo(() => {
      return getLang();
    }, [locale]);

    const disabledDate = (currentDate: Dayjs): boolean => {
      if (!disablePrevDates) {
        return false;
      }
      const now = dayjs();
      return now > currentDate;
    };

    const popupStyle: React.CSSProperties = isPopupFixed
      ? { position: 'fixed' }
      : {};

    return (
      <div
        id={id}
        style={style}
        ref={ref}
        className={className}
        data-testid={dataTestId}
      >
        <ConfigProvider
          locale={lang}
          theme={{
            components: {
              DatePicker: {
                activeBorderColor: colors.goldColor,
                hoverBorderColor: colors.gray80Color,
                cellRangeBorderColor: colors.goldColor,
                cellHoverWithRangeBg: colors.palettesGold10,
                cellActiveWithRangeBg: colors.backgroundHover,
                boxShadowSecondary: '0px 1px 2px 0px rgba(18, 18, 18, 0.20)',
                cellBgDisabled: colors.white5Color,
                cellHoverBg: colors.white5Color
              }
            },
            token: {
              colorLink: colors.goldColor,
              colorLinkActive: colors.palettesGold60,
              colorLinkHover: colors.goldColor,
              colorPrimary: colors.goldColor,
              colorPrimaryBorder: colors.goldColor,
              colorPrimaryHover: colors.goldColor,
              controlItemBgActive: colors.palettesGold10,
              colorTextPlaceholder: colors.gray60Color,
              colorBgContainerDisabled: colors.white5Color
            }
          }}
        >
          {type === 'day' ? (
            <AntDatePicker
              style={{ boxShadow: 'none' }}
              value={shownDatePickerValue}
              onChange={onDatePickerChangeFunction}
              className={classNames(
                styles.picker,
                styles['date-picker'],
                dateIntervalType === DateIntervalType.Month &&
                  styles['date-picker--month'],
                disabled && styles['picker--disabled']
              )}
              placeholder={datePickerPlaceholder}
              picker={dateIntervalType}
              disabled={disabled}
              format={
                dateIntervalType === DateIntervalType.Month
                  ? 'MM-YYYY'
                  : 'DD-MM-YYYY'
              }
              disabledDate={disabledDate}
              suffixIcon={
                <Icon
                  name="calendar"
                  className={styles['ant-picker-suffix']}
                  size="m"
                />
              }
              allowClear={{
                clearIcon: (
                  <Icon
                    name="close"
                    className={styles['ant-picker-suffix']}
                    size="m"
                  />
                )
              }}
              popupStyle={popupStyle}
              // @ts-ignore
              getPopupContainer={(trigger: HTMLElement) => trigger?.parentNode}
            />
          ) : (
            <RangePicker
              style={{ boxShadow: 'none' }}
              value={
                rangePickerValue && [
                  dayjs(rangePickerValue && rangePickerValue[0]),
                  dayjs(rangePickerValue && rangePickerValue[1])
                ]
              }
              onChange={onRangePickerChangeFunction}
              className={classNames(
                styles.picker,
                styles['range-picker'],
                dateIntervalType === DateIntervalType.Month &&
                  styles['range-picker--month'],
                disabled && styles['picker--disabled']
              )}
              placeholder={[rangePickerPlaceholder, rangePickerPlaceholder]}
              picker={dateIntervalType}
              separator={'⏤'}
              disabled={[disabled, disabled]}
              format={
                dateIntervalType === DateIntervalType.Month
                  ? 'MM-YYYY'
                  : 'DD-MM-YYYY'
              }
              disabledDate={disabledDate}
              suffixIcon={
                <Icon
                  name="calendar"
                  className={styles['ant-picker-suffix']}
                  size={'s'}
                />
              }
              allowClear={{
                clearIcon: (
                  <Icon
                    name={'close'}
                    className={styles['ant-picker-suffix']}
                    size={'s'}
                  />
                )
              }}
              popupStyle={popupStyle}
              // @ts-ignore
              getPopupContainer={(trigger: HTMLElement) => trigger?.parentNode}
            />
          )}
        </ConfigProvider>
      </div>
    );
  }
);

DatePicker.displayName = 'DatePicker';
