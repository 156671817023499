import { handleBackendError, omniApiConfiguration, wait } from '@services';
import { SelectItem, uploadFileBySignedLink } from '@xq/ui-kit';
import {
  FileInfoDTOResponse,
  FileRestrictionsDTOResponse,
  OrganizationEditDTORequest,
  OrganizationEditPageApi
} from '@xq/omni-gateway-frontend-client';

interface OrganizationInfoData {
  name: string;
  description?: string;
  countries: SelectItem[];
  currencies: SelectItem[];
  managers: SelectItem[];
  agreementFilesRestrictions: FileRestrictionsDTOResponse;
  agreementId?: string;
  agreementDate?: Date;
  currency: SelectItem;
  country: SelectItem;
  manager?: SelectItem;
  agreementFiles?: FileInfoDTOResponse[];
}

export interface EditOrganizationService {
  fetchData(organizationUuid: string): Promise<OrganizationInfoData>;

  update(
    organizationUuid: string,
    organization: OrganizationEditDTORequest
  ): Promise<void>;

  addOrganizationAgreementFiles(
    organizationUuid: string,
    agreementFiles: Array<File>
  ): Promise<void>;

  getAgreementFile(
    agreementFileName: string,
    organizationUuid: string
  ): Promise<string>;
}

const organizationInfoGateway = new OrganizationEditPageApi(
  omniApiConfiguration
);

export class EditOrganizationServiceApi implements EditOrganizationService {
  async fetchData(organizationUuid: string): Promise<OrganizationInfoData> {
    try {
      return await organizationInfoGateway.organizationEditPageControllerGetPageData(
        { organizationUuid }
      );
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async update(
    organizationUuid: string,
    organization: OrganizationEditDTORequest
  ): Promise<void> {
    try {
      await organizationInfoGateway.organizationEditPageControllerUpdateOrganizationInfo(
        {
          organizationUuid: organizationUuid,
          organizationEditDTORequest: organization
        }
      );
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.update.name);
    }
  }

  async addOrganizationAgreementFiles(
    organizationUuid: string,
    agreementFiles: Array<File>
  ): Promise<void> {
    try {
      const fileRequests = agreementFiles?.map((file) => {
        this.getFileUploadRequest(organizationUuid, file);
      });

      await Promise.all(fileRequests);
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.addOrganizationAgreementFiles.name
      );
    }
  }

  async getAgreementFile(
    organizationUuid: string,
    agreementFileName: string
  ): Promise<string> {
    try {
      return await organizationInfoGateway.organizationEditPageControllerDownloadAgreementFile(
        {
          agreementFileName: agreementFileName,
          organizationUuid: organizationUuid
        }
      );
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.getAgreementFile.name
      );
    }
  }

  async getFileUploadRequest(organizationUuid: string, file: File) {
    const fileSaveLink =
      await organizationInfoGateway.organizationEditPageControllerGetUploadOrganizationAgreementFileUrl(
        {
          organizationUuid: organizationUuid,
          fileInfoDTORequest: {
            byteSize: file.size,
            name: file.name
          }
        }
      );
    return uploadFileBySignedLink(fileSaveLink, file);
  }
}

const fetchDataMock: OrganizationInfoData = {
  name: '2NS',
  description: 'Company',
  countries: [
    { label: 'Finland', value: 'FIN' },
    { label: 'Sweden', value: 'SWE' }
  ],
  currencies: [
    { label: 'Euro', value: 'EUR' },
    { label: 'Usd', value: 'USD' }
  ],
  managers: [
    { value: '1', label: 'Nikita Pakhomov' },
    { value: '2', label: 'Markus' }
  ],
  agreementFilesRestrictions: {
    maxByteSize: 2000,
    allowedMimeTypes: 'application/pdf,application/msword'
  },
  agreementId: '1',
  agreementDate: new Date('2022-06-30T09:04:05.578Z'),
  currency: { label: 'Euro', value: 'EUR' },
  country: { label: 'Finland', value: 'FIN' },
  manager: { value: '1', label: 'Nikita Pakhomov' },
  agreementFiles: [
    {
      byteSize: 2345,
      name: 'Agreement.pdf'
    }
  ]
};

export class EditOrganizationServiceMock implements EditOrganizationService {
  async fetchData(organizationUuid: string): Promise<OrganizationInfoData> {
    try {
      await wait(1000);
      return fetchDataMock;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async update(
    organizationUuid: string,
    organization: OrganizationEditDTORequest
  ): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.update.name);
    }
  }

  async addOrganizationAgreementFiles(
    organizationUuid: string,
    agreementFiles: Array<File>
  ): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.addOrganizationAgreementFiles.name
      );
    }
  }

  async getAgreementFile(
    organizationUuid: string,
    agreementFileName: string
  ): Promise<string> {
    try {
      await wait(1000);
      return 'testImageFile';
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.getAgreementFile.name
      );
    }
  }
}
