import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import {
  BreadcrumbsDropdownItem,
  BreadcrumbsItem,
  ComponentProps,
  Flag,
  Icon,
  Typography
} from '@components';
import styles from './Breadcrumbs.module.scss';
import { colors, offsets } from '@componentsStyles';
import { NavLinkProps } from 'react-router-dom';

export interface BreadcrumbsProps extends ComponentProps {
  /**
   * All items list
   */
  items: BreadcrumbsItem[];
  /**
   * Navigation link for case sensitive items
   */
  activeItemLink?: string;
  /**
   * NavLink to use router
   */
  NavLink: React.ForwardRefExoticComponent<
    NavLinkProps & React.RefAttributes<HTMLAnchorElement>
  >;
  /**
   * Disabled breadcrumbs
   */
  disabled?: boolean;
  /**
   * Is search available in dropdown
   */
  isSearched?: boolean;
  /**
   * Shown text in a search input
   */
  searchPlaceholder?: string;
  /**
   * Shown when search result is empty
   */
  noItemsText?: string;
}

export const Breadcrumbs: FC<BreadcrumbsProps> = (props) => {
  const {
    className,
    id,
    style,
    dataTestId,
    items,
    NavLink,
    disabled,
    isSearched = true,
    searchPlaceholder = 'Search',
    noItemsText = 'No items',
    activeItemLink
  } = props;

  const [shownDropdown, setShownDropdown] = useState<BreadcrumbsItem>(null);
  const [shownSecondaryDropdown, setShownSecondaryDropdown] =
    useState<BreadcrumbsDropdownItem>(null);

  const [search, setSearch] = useState<string>('');

  const ref = useRef<HTMLDivElement>(null);

  const toggleDropdown = (item: BreadcrumbsItem) => {
    if (disabled) {
      return;
    }

    if (item === shownDropdown) {
      setShownDropdown(null);
      setShownSecondaryDropdown(null);
    } else {
      setShownDropdown(item);
    }
    setSearch('');
  };

  const toggleSecondaryDropdown = (item: BreadcrumbsDropdownItem) => {
    if (item === shownSecondaryDropdown) {
      setShownSecondaryDropdown(null);
    } else {
      setShownSecondaryDropdown(item);
    }
  };

  const visibleDropdownItems = (
    dropdownItems: BreadcrumbsDropdownItem[]
  ): BreadcrumbsDropdownItem[] => {
    if (search && isSearched) {
      return dropdownItems?.filter(
        (item) => item?.label?.toLowerCase()?.indexOf(search.toLowerCase()) > -1
      );
    }
    return dropdownItems;
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setShownDropdown(null);
      setShownSecondaryDropdown(null);
    }
  };

  const handleClickKeyboard = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setShownDropdown(null);
      setShownSecondaryDropdown(null);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    document.addEventListener('keydown', handleClickKeyboard, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
      document.removeEventListener('keydown', handleClickKeyboard, true);
    };
  }, []);

  return (
    <div
      ref={ref}
      id={id}
      className={classNames(
        className,
        disabled ? styles['breadcrumbs--disabled'] : styles.breadcrumbs
      )}
      style={style}
      data-testid={dataTestId}
    >
      {items?.map((item, itemIdx) => {
        const searchedItemDropdown = item.dropdown?.filter((item) => {
          if (item.label) {
            return item.label?.toLowerCase()?.includes(search.toLowerCase());
          } else {
            return true;
          }
        });

        return (
          <div className={styles['title-wrapper']} key={itemIdx}>
            <div
              className={
                shownDropdown === item && !disabled
                  ? styles['title--dropdown']
                  : styles.title
              }
            >
              <div>
                <NavLink
                  data-testid="item-label"
                  className={
                    shownDropdown === item && !disabled
                      ? styles['title--dropdown']
                      : styles.title
                  }
                  key={itemIdx}
                  to={item.url}
                >
                  {(item?.customTitle || item?.dropdown?.[0]?.title) && (
                    <Typography variant="system">
                      {`${
                        item?.customTitle ||
                        item?.dropdown?.[0]?.title?.toLowerCase()
                      }:`}
                      &nbsp;
                    </Typography>
                  )}

                  <Typography variant="system">
                    {item?.countryIso3 && (
                      <Flag
                        country={item.countryIso3}
                        width={14}
                        height={10}
                        className={offsets['mr-6']}
                      />
                    )}
                    {item.label}
                  </Typography>
                </NavLink>
              </div>

              {item.dropdown && (
                <>
                  <div
                    data-testid="dropdown-icon"
                    onClick={() => toggleDropdown(item)}
                    className={styles['dropdown-icon']}
                  >
                    <Icon name="chevron-down" size="s" />
                  </div>

                  {shownDropdown === item && !disabled && (
                    <div
                      className={styles.dropdown}
                      style={{ paddingTop: isSearched ? 10 : 0 }}
                    >
                      {isSearched && (
                        <div className={styles['search-block']}>
                          <input
                            type="text"
                            className={styles.search}
                            placeholder={searchPlaceholder}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <Icon
                            data-testid="close-icon"
                            onClick={() => setSearch('')}
                            name="close"
                            size="xs"
                            className={styles['search-close']}
                          />
                        </div>
                      )}
                      <div className={styles['dropdown-list']}>
                        {/* filtering items by search */}

                        {search &&
                        isSearched &&
                        searchedItemDropdown.length === 0 ? (
                          <Typography
                            variant="system"
                            element="div"
                            className={styles['dropdown-no-items']}
                          >
                            {noItemsText}
                          </Typography>
                        ) : (
                          (search && isSearched
                            ? searchedItemDropdown
                            : item.dropdown
                          ).map(
                            (
                              {
                                list,
                                title,
                                countryIso3,
                                url,
                                label,
                                disabled
                              },
                              index
                            ) => {
                              return (
                                <Fragment key={index}>
                                  {title && (
                                    <Typography
                                      variant="system-heading-2"
                                      element="div"
                                      className={
                                        styles['dropdown-wrapper-title']
                                      }
                                      color={colors.gray60Color}
                                    >
                                      {title}
                                    </Typography>
                                  )}
                                  {visibleDropdownItems(list)?.length > 0 ? (
                                    visibleDropdownItems(list).map(
                                      (dropdownItem, dropdownIdx) => {
                                        const hasSubDropdown =
                                          dropdownItem.dropdown?.length > 0;
                                        const isItemDisabled =
                                          dropdownItem.disabled;

                                        return (
                                          <div
                                            data-testid="dropdown-item"
                                            key={dropdownIdx}
                                            className={classNames(
                                              styles['dropdown-wrapper'],
                                              {
                                                [styles[
                                                  'dropdown-wrapper--active'
                                                ]]:
                                                  !isItemDisabled &&
                                                  (shownSecondaryDropdown ===
                                                    dropdownItem ||
                                                    (activeItemLink
                                                      ? activeItemLink ===
                                                        dropdownItem.url
                                                      : dropdownItem.label?.toLowerCase() ===
                                                        item.label?.toLowerCase())),
                                                [styles[
                                                  'dropdown-wrapper--disabled'
                                                ]]: isItemDisabled
                                              }
                                            )}
                                          >
                                            <NavLink
                                              className={styles.link}
                                              to={dropdownItem.url}
                                              onClick={(e) => {
                                                if (isItemDisabled) {
                                                  e.preventDefault();
                                                  return;
                                                }
                                                setShownDropdown(null);
                                                setShownSecondaryDropdown(null);
                                              }}
                                            >
                                              <Typography
                                                variant="system"
                                                element="div"
                                                className={
                                                  styles[
                                                    'dropdown-wrapper-item'
                                                  ]
                                                }
                                              >
                                                {dropdownItem?.countryIso3 && (
                                                  <Flag
                                                    country={
                                                      dropdownItem.countryIso3
                                                    }
                                                    width={14}
                                                    height={10}
                                                    className={offsets['mr-6']}
                                                  />
                                                )}
                                                {dropdownItem.label}
                                              </Typography>
                                            </NavLink>
                                            {hasSubDropdown && (
                                              <div
                                                data-testid="dropdown2-icon"
                                                onClick={() =>
                                                  !isItemDisabled
                                                    ? toggleSecondaryDropdown(
                                                        dropdownItem
                                                      )
                                                    : null
                                                }
                                                className={
                                                  styles[
                                                    'dropdown-wrapper-icon'
                                                  ]
                                                }
                                              >
                                                <Icon
                                                  name="chevron-right"
                                                  size="s"
                                                />
                                              </div>
                                            )}
                                            {shownSecondaryDropdown ===
                                              dropdownItem && (
                                              <div
                                                className={
                                                  styles[
                                                    'dropdown-list-secondary'
                                                  ]
                                                }
                                                style={{
                                                  transform: `translate(1px, ${
                                                    (dropdownItem.dropdown
                                                      .length *
                                                      36) /
                                                      2 -
                                                    18
                                                  }px)`
                                                }}
                                              >
                                                {dropdownItem.dropdown?.map(
                                                  (
                                                    subDropdownItem,
                                                    subDropdownIdx
                                                  ) => {
                                                    return (
                                                      <div
                                                        data-testid="sec-dropdown-item"
                                                        className={
                                                          styles[
                                                            'dropdown-wrapper-secondary'
                                                          ]
                                                        }
                                                        key={subDropdownIdx}
                                                      >
                                                        <NavLink
                                                          className={
                                                            styles.link
                                                          }
                                                          to={`${dropdownItem.url}/${subDropdownItem.url}`}
                                                          onClick={() => {
                                                            setShownDropdown(
                                                              null
                                                            );
                                                            setShownSecondaryDropdown(
                                                              null
                                                            );
                                                          }}
                                                        >
                                                          <Typography
                                                            variant="system"
                                                            element="div"
                                                            className={
                                                              styles[
                                                                'dropdown-wrapper-item-secondary'
                                                              ]
                                                            }
                                                          >
                                                            {subDropdownItem?.countryIso3 && (
                                                              <Flag
                                                                country={
                                                                  subDropdownItem.countryIso3
                                                                }
                                                                width={14}
                                                                height={10}
                                                                className={
                                                                  offsets[
                                                                    'mr-6'
                                                                  ]
                                                                }
                                                              />
                                                            )}
                                                            {
                                                              subDropdownItem.label
                                                            }
                                                          </Typography>
                                                        </NavLink>
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        );
                                      }
                                    )
                                  ) : (
                                    <div
                                      data-testid="dropdown-item"
                                      key={title}
                                      className={classNames(
                                        styles['dropdown-wrapper'],
                                        {
                                          [styles['dropdown-wrapper--active']]:
                                            !disabled &&
                                            (activeItemLink
                                              ? activeItemLink === item.url
                                              : label?.toLowerCase() ===
                                                item.label?.toLowerCase()),
                                          [styles[
                                            'dropdown-wrapper--disabled'
                                          ]]: disabled
                                        }
                                      )}
                                    >
                                      <NavLink
                                        className={styles.link}
                                        to={url}
                                        onClick={(e) => {
                                          if (disabled) {
                                            e.preventDefault();
                                            return;
                                          }
                                          setShownDropdown(null);
                                          setShownSecondaryDropdown(null);
                                        }}
                                      >
                                        <Typography
                                          variant="system"
                                          element="div"
                                          className={
                                            styles['dropdown-wrapper-item']
                                          }
                                        >
                                          {countryIso3 && (
                                            <Flag
                                              country={countryIso3}
                                              width={14}
                                              height={10}
                                              className={offsets['mr-6']}
                                            />
                                          )}
                                          {label}
                                        </Typography>
                                      </NavLink>
                                    </div>
                                  )}
                                </Fragment>
                              );
                            }
                          )
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>

            {itemIdx !== items.length - 1 && (
              <Icon name="chevron-right" size="s" className={styles.next} />
            )}
          </div>
        );
      })}
    </div>
  );
};

Breadcrumbs.displayName = 'Breadcrumbs';
