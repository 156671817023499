import { handleBackendError, omniApiConfiguration, wait } from '@services';
import {
  OrganizationData,
  OrganizationDbConnection
} from '@pages/Organizations/Organization/dataTypes';
import { OrganizationsInfoPageApi } from '@xq/omni-gateway-frontend-client';
import { TFunction } from 'i18next';
import {
  convertBillingFrequencyToText,
  convertPaymentMethodToText
} from '@xq/ui-kit';

export interface OrganizationService {
  fetchData(organizationUuid: string, t: TFunction): Promise<OrganizationData>;

  assignModeltreeVersion(
    organizationUuid: string,
    modeltreeVersion: string
  ): Promise<void>;
}

const omniOrganizationsInfoGateway = new OrganizationsInfoPageApi(
  omniApiConfiguration
);

export class OrganizationServiceApi implements OrganizationService {
  async fetchData(
    organizationUuid: string,
    t: TFunction
  ): Promise<OrganizationData> {
    try {
      const response =
        await omniOrganizationsInfoGateway.organizationInfoPageControllerGetPageData(
          {
            organizationUuid,
            maximumYearsForPaymentDiagram: 4,
            maximumActivityLogs: 4
          }
        );
      return {
        ...response,
        licenses: response?.licenseInfo?.map((el) => {
          return {
            uuid: el?.uuid,
            license: el?.licenseName,
            activeUsers: el?.activeUsers,
            invoicing: convertBillingFrequencyToText(el?.billingFrequency),
            paymentMethod: convertPaymentMethodToText(el?.paymentMethod),
            activationDate: el?.createdAt,
            billingStartDate: el?.billingStartDate,
            expiryDate: el?.expireAt,
            isTrial: el?.isTrial
          };
        }),
        lastActivities: response?.lastActivities?.map((el) => {
          return { ...el, email: el?.email, date: el?.date };
        }),
        payments: response?.payments?.map((el) => {
          return { ...el, label: `${t('common.payments')} ${el.label}` };
        })
      };
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async assignModeltreeVersion(
    organizationUuid: string,
    modeltreeVersion: string
  ): Promise<void> {
    try {
      await omniOrganizationsInfoGateway.organizationInfoPageControllerAssignSpeicificModeltreeVersion(
        {
          organizationUuid,
          modeltreeVersion
        }
      );
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.assignModeltreeVersion.name
      );
    }
  }
}

const organizationMock: OrganizationData = {
  organizationInfo: {
    name: '2NS',
    description: 'Description',
    countryIso3: 'FIN',
    currencyIso3: 'EUR',
    agreementId: '1234',
    agreementDate: new Date(2020, 21, 2)
  },
  invoicesState: {
    currencyIso3: 'EUR',
    lastInvoiceDate: new Date(2022, 1, 1),
    nextInvoiceDate: new Date(2023, 1, 1),
    lastPayment: 10000,
    nextPayment: 12000
  },
  licenses: [
    {
      uuid: '123',
      license: 'ModelTree Valuation',
      activeUsers: 10,
      invoicing: 'Monthly',
      paymentMethod: 'Prepayment',
      activationDate: new Date('2020-02-22T08:00:00.000z'),
      billingStartDate: new Date('2020-11-30T08:00:00.000z')
    },
    {
      uuid: '124',
      license: 'Fundy',
      activeUsers: 33,
      invoicing: 'Annually',
      paymentMethod: 'Prepayment',
      activationDate: new Date('2021-12-02T18:30:00.000z'),
      billingStartDate: new Date('2022-11-09T08:00:00.000z'),
      expiryDate: new Date('2023-03-03T08:00:00.000z'),
      isTrial: true
    }
  ],
  payments: [
    {
      label: 'Payments 2022',
      value: 890000,
      uuid: '1'
    },
    {
      label: 'Payments 2021',
      value: 710000,
      uuid: '2'
    },
    {
      label: 'Payments 2020',
      value: 650000,
      uuid: '3'
    }
  ],
  lastActivities: [
    {
      application: 'ModelTree',
      date: new Date('2023-04-03T08:00:00.000z'),
      email: 'max.verner@exquance.com'
    },
    {
      application: 'ModelTree',
      date: new Date('2023-04-02T18:20:00.000z'),
      email: 'ann.green@exquance.com'
    },
    {
      application: 'Fundy',
      date: new Date('2023-04-02T17:34:21.000z'),
      email: 'john.hopsen@exquance.com'
    }
  ],
  userStatistic: {
    totalUsers: 120,
    activeUsers: 98,
    administrators: 5
  },
  contactPersons: [
    {
      uuid: '1',
      position: 'CIO',
      name: 'Horatio Morrison',
      phone: '+ 3 219 987 11 11',
      email: 'horatio.morrison@2ns.com',
      comment: "Managing IT staff and organization's software development needs"
    },
    {
      uuid: '2',
      position: 'Senior manager',
      name: 'Shana Brooks',
      phone: '+ 3 217 467 10 90',
      email: 'shana.brooks@2ns.com',
      comment: 'Managing bills and add new users'
    }
  ],
  modeltreeVersions: [
    //todo
    {
      label: '',
      value: ''
    }
  ],
  modeltreeInfo: {
    //todo
    errorDetails: '',
    needToUpdate: false
  }
};

const dbConnectionMock: OrganizationDbConnection = {
  isLocal: false,
  version: '9.71.278',
  error: 'Test connection failed'
};

export class OrganizationServiceMock implements OrganizationService {
  async fetchData(
    organizationUuid: string,
    t: TFunction
  ): Promise<OrganizationData> {
    try {
      await wait(1000);
      return organizationMock;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async assignModeltreeVersion(
    organizationUuid: string,
    modeltreeVersion: string
  ): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.assignModeltreeVersion.name
      );
    }
  }
}
