import React, { FC } from 'react';
import classNames from 'classnames';
import { ComponentProps, Typography } from '@components';
import styles from './Tooltip.module.scss';
import { TextAlignType, TooltipType } from './dataTypes';
import { default as TooltipNew } from 'antd/lib/tooltip';

export interface TooltipProps extends ComponentProps {
  content: string;
  type?: TooltipType;
  disabled?: boolean;
  textAlign?: TextAlignType;
  children?: React.ReactNode;
}

export const Tooltip: FC<TooltipProps> = (props) => {
  const {
    className,
    id,
    children,
    style,
    dataTestId,
    type = 'top',
    content,
    disabled = false,
    textAlign = 'center'
  } = props;

  return (
    <div id={id} data-testid={dataTestId} style={style}>
      <TooltipNew
        className={classNames(className, styles.container)}
        title={
          !disabled && <Typography variant="label-2">{content}</Typography>
        }
        placement={type}
        color="#3d3d3d"
        overlayInnerStyle={{
          padding: '4px 12px',
          borderRadius: '2px',
          color: '#ffffff',
          minHeight: '27px',
          textAlign: textAlign
        }}
      >
        <span>{children}</span>
      </TooltipNew>
    </div>
  );
};

Tooltip.displayName = 'Tooltip';
