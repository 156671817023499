import React, {
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import styles from './Builds.module.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Alert,
  changeSortDirection,
  Col,
  EmptyState,
  offsets,
  openStatusNotification,
  Row,
  ScrollTopButton,
  Search,
  SortDirection,
  Table,
  TableAction,
  TableColumn,
  TableRow,
  Typography
} from '@xq/ui-kit';
import { BuildsService, BuildsServiceApi } from './builds-service';
import {
  checkEmptyState,
  checkNoMoreData,
  debounce,
  downloadFile,
  getStatusNotificationTranslations
} from '@services';
import { SidemenuContext, SidemenuContextData } from '@context';
import { ListMetaInfo, ListParams } from 'interfaces';
import { SORT_DIRECTIONS } from '@constants';
import { createBuildsColumns, createBuildsRows } from './utils';
import { useDebounce } from '@hooks';
import { config } from '@config';
import { BuildsItem } from '@pages/ModelTree';
import { getRouteUrl, ROUTES } from '@router';

export const Builds: FC = () => {
  const service: BuildsService = new BuildsServiceApi();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  /** Tables and base information  */
  const [builds, setBuilds] = useState<BuildsItem[]>([]);
  const [buildsMeta, setBuildsMeta] = useState<ListMetaInfo>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [noResults, setNoResults] = useState<boolean>(false);
  const [sortOrder, setSortOrder] = useState<SortDirection>(
    SORT_DIRECTIONS.ASC as SortDirection
  );
  const [sortBy, setSortBy] = useState<string>('createdAt');
  const [noMoreData, setNoMoreData] = useState<boolean>(false);
  const [isEmptyState, setIsEmptyState] = useState<boolean>(false);

  const [searchValue, setSearchValue] = useState<string>(
    searchParams.get('search') || ''
  );
  const debouncedSearchValue = useDebounce(searchValue, config.debounceDelay);

  /** values to alert */
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  const [submitText, setSubmitText] = useState<string>('');
  const [alertText, setAlertText] = useState<string>('');
  const [alertSubmitFunction, setAlertSubmitFunction] = useState<() => void>();

  /* Table */
  const buildsColumns: TableColumn[] = createBuildsColumns(t);

  const buildsRows = useMemo(() => {
    return createBuildsRows(t, builds);
  }, [builds]);

  const actions: TableAction[] = [
    {
      key: 'createRelease',
      title: t('modelTree.createRelease'),
      icon: 'release',
      onClick: redirectToCreateRelease
    },
    {
      key: 'download',
      title: t('common.download'),
      icon: 'download',
      onClick: downloadBuild
    },
    {
      key: 'delete',
      title: t('common.delete'),
      icon: 'trash',
      onClick: deleteBuildSubmit
    }
  ];

  const filterActionsForRow = (actions: TableAction[], row: TableRow) => {
    const build = builds?.find((el) => el.uuid === row.id);

    if (!build || build?.releaseVersion) {
      return [];
    }

    return actions;
  };

  async function downloadBuild(row: TableRow) {
    try {
      const url = `${config.apiUrl}/omni/model-tree-builds-list-page/build/${row.id}/file`;
      await downloadFile(url);
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200,
        message: t('notifications.buildIsDownloading')
      });
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  function deleteBuildSubmit(build: TableRow) {
    const buildInfo = builds?.find((el) => el.uuid === build.id);
    setAlertText(
      `${t(
        'alerts.areYouSureYouWantToDelete'
      )} <span style='text-transform: lowercase'>${t(
        'modelTree.buildId'
      )}</span> <b>${buildInfo?.buildId}</b>?`
    );
    setSubmitText(t('common.delete'));
    setAlertSubmitFunction(() => () => deleteBuild(build));
    setIsAlertOpen(true);
  }

  async function deleteBuild(build: TableRow) {
    try {
      await service.deleteBuild(Number(build.id));
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200,
        message: t('notifications.successfullyDeleted')
      });
      const newBuilds = builds?.filter((el) => build.id !== el.uuid);
      setBuilds(newBuilds);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  function redirectToCreateRelease(row: TableRow) {
    const build = builds?.find((build) => build.uuid === row.id);

    navigate(getRouteUrl(ROUTES.MODEL_TREE.CREATE_RELEASE, null), {
      state: {
        buildId: build.buildId,
        buildBranch: build?.branchName
      }
    });
  }

  useEffect(() => {
    fetchBuilds({
      searchValue: debouncedSearchValue,
      sortOrder: sortOrder,
      sortBy: sortBy
    });
  }, [sortOrder, sortBy, debouncedSearchValue]);

  const updateSearchParams = useCallback(
    debounce((newValue) => {
      if (newValue) {
        searchParams.set('search', newValue);
      } else {
        searchParams.delete('search');
      }
      setSearchParams(searchParams);
    }, config.debounceDelay),
    [searchParams, setSearchParams]
  );

  useEffect(() => {
    const searchParamValue = searchParams.get('search') || '';
    setSearchValue(searchParamValue);
  }, [searchParams]);

  const handleSearchValue = (newValue: string) => {
    setSearchValue(newValue);
    updateSearchParams(newValue);
  };

  async function fetchBuilds(queryParams?: ListParams) {
    setIsLoading(true);
    try {
      const response = await service.fetchBuilds(queryParams);

      const hasResults = !response.items || response?.items?.length === 0;
      setNoResults(hasResults);
      setBuilds(response?.items);
      checkNoMoreData(
        response?.items?.length,
        response?.meta?.totalItems,
        setNoMoreData
      );
      checkEmptyState({ setIsEmptyState, meta: response?.meta, searchValue });
      setBuildsMeta(response?.meta);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function loadMoreBuilds() {
    try {
      if (noMoreData) {
        return;
      }

      const response = await service.fetchBuilds({
        page: ++buildsMeta.currentPage,
        searchValue: debouncedSearchValue,
        sortOrder: sortOrder,
        sortBy: sortBy
      });

      const { items } = response;
      checkNoMoreData(items?.length, response?.meta?.totalItems, setNoMoreData);
      setBuilds([...builds, ...items]);
      setBuildsMeta(response?.meta);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  function updateSortParams(key: string) {
    let updatedSortBy = sortBy;
    let updatedSortOrder = sortOrder;

    if (key === sortBy) {
      updatedSortOrder = changeSortDirection(sortOrder);
      setSortOrder(updatedSortOrder);
    } else {
      updatedSortBy = key;
      setSortBy(updatedSortBy);
    }
  }

  /** Sidemenu */
  const sidemenuContext: SidemenuContextData = useContext(SidemenuContext);

  useEffect(() => {
    sidemenuContext.setActiveMenu(ROUTES.MODEL_TREE.BUILDS);
  }, [sidemenuContext]);

  return (
    <Fragment>
      <Row cols={10}>
        <Col col={9} className={styles.heading}>
          <Typography element="div" variant="h2">
            {t('routes.modelTree.builds')}
          </Typography>
        </Col>
      </Row>

      <Row cols={10} style={{ marginRight: 0 }}>
        {!isLoading && isEmptyState && (
          <EmptyState
            heading={t('uiKit.oopsItIsEmpty')}
            description={t('alerts.looksLikeNoOneCame')}
            isCentered={true}
          />
        )}
      </Row>

      {!isEmptyState && (
        <>
          <Row cols={10}>
            <Col col={6} lg={3} className={offsets['mb-20']}>
              <Search
                placeholder={t('modelTree.searchByBuildBranchOrId')}
                value={searchValue}
                onChange={handleSearchValue}
                disabled={isLoading}
              />
            </Col>
          </Row>
          <Row cols={10}>
            <Col col={9} md={7} lg={6}>
              <Table
                isLoading={isLoading}
                className={offsets['mb-40']}
                rows={buildsRows}
                columns={buildsColumns}
                noMoreData={noMoreData}
                noMoreItemsText={t('common.noMoreItems')}
                onLoading={loadMoreBuilds}
                maxHeight="640px"
                minWidth={900}
                onSort={updateSortParams}
                sortOrder={sortOrder}
                sortBy={sortBy}
                noResults={noResults}
                noResultsText={t('common.noResults')}
                actions={actions}
                filterActionsForRow={filterActionsForRow}
              />
            </Col>
          </Row>
        </>
      )}

      <Alert
        isOpen={isAlertOpen}
        onSubmit={() => alertSubmitFunction()}
        onClose={() => setIsAlertOpen(false)}
        cancelText={t('common.cancel')}
        submitText={submitText}
      >
        <div dangerouslySetInnerHTML={{ __html: alertText }} />
      </Alert>

      <ScrollTopButton />
    </Fragment>
  );
};

Builds.displayName = 'Builds';
