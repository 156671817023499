export interface FileSaveLink {
  getLink?: string | null;
  saveLink: string;
  formData: object;
}

export async function uploadFileBySignedLink(
  fileSaveLink: FileSaveLink,
  file: File,
  abortSignal?: AbortSignal
) {
  const formData = new FormData();

  for (const [key, value] of Object.entries(fileSaveLink.formData)) {
    formData.append(key, value);
  }

  formData.append('Content-Type', file.type);
  formData.append('file', file, file.name);

  await fetch(fileSaveLink.saveLink, {
    method: 'POST',
    body: formData,
    signal: abortSignal,
    mode: 'no-cors'
  })
    .then(async (response) => {
      if (response.ok) return;
      throw await response.text();
    })
    .catch((e) => {
      console.error(`Error while uploadFileBySignedLink: ${e}`);
    });
}
