import { SortDirection, TableColumn, TableDataType } from '@components';
import CSS from 'csstype';

export const changeSortDirection = (
  direction: SortDirection
): SortDirection => {
  if (direction === 'ASC') {
    return 'DESC';
  }
  return 'ASC';
};

export const changeTableDataType = (type: TableDataType): TableDataType => {
  return type;
};

interface CellStyles {
  column: TableColumn;
  isScrollable?: boolean;
  isHeader?: boolean;
  isCurrentRowSelected?: boolean;
}

export const cellStyles = ({
  column,
  isScrollable = false,
  isHeader = false,
  isCurrentRowSelected
}: CellStyles) => {
  const styles: CSS.Properties = {};
  styles.background = 'inherit';
  styles.height = !isHeader ? '64px' : 'unset';
  styles.boxSizing = 'border-box';
  styles.width = column?.width + 'px' || 'auto';
  styles.textAlign = column?.alignment || 'left';

  styles.paddingTop = '12px';
  styles.paddingBottom = '12px';

  if (isCurrentRowSelected) {
    styles.paddingTop = '16px';
    styles.paddingBottom = '16px';
  }

  if (column?.paddingBottom) {
    styles.paddingBottom = column?.paddingBottom + 'px';
  }
  if (column?.paddingRight) {
    styles.paddingRight = column?.paddingRight + 'px';
  }
  if (column?.paddingLeft) {
    styles.paddingLeft = column?.paddingLeft + 'px';
  }
  if (!isHeader) {
    styles.wordBreak = 'break-word';
  }

  if (column?.fixed && isScrollable) {
    styles.position = 'sticky';
    // styles.zIndex = 10;
    // todo fix styles for right fixed column
    // todo fix box shadow
    // if (column?.fixed === 'right') {
    //   styles.right = '0px';
    //   styles.boxShadow = '3px 0px 11px 0px rgba(5,5,5,.06)';
    // }
    // if (column?.fixed === 'left') {
    styles.left = '0px';
    styles.boxShadow = '3px 0px 11px 0px rgba(5,5,5,.06)';
    // }
  }

  return styles;
};
