import React, { FC, useMemo } from 'react';
import {
  Card,
  ComponentProps,
  DatabaseVersionTranslations,
  DropdownItemProps,
  Icon,
  Radio,
  SkeletonPlaceholder,
  Tooltip,
  Typography
} from '@components';
import cn from 'classnames';
import styles from './DatabaseVersion.module.scss';
import { colors, offsets } from '@componentsStyles';
import { formatDate, useComponentVisible } from '@componentsUtils';
import { DASH } from '@constants';

export interface DatabaseVersionProps extends ComponentProps {
  version?: string;
  versions?: DropdownItemProps[];
  lastUpdate?: {
    date: Date;
    version: string;
  };
  translations?: DatabaseVersionTranslations;
  error?: string;
  isLoading?: boolean;
  isSeeDetails?: boolean;
  onSeeDetails?: () => void;
  assignedVersion?: string;
  setAssignedVersion?: (version: string) => void;
  warningMessage?: string;
  online?: boolean;
}

const defaultTranslation: DatabaseVersionTranslations = {
  seeDetails: 'See details',
  modelTreeVersions: 'ModelTree versions',
  type: 'Type',
  databaseVersion: 'Database version',
  errorDetails: 'Error details',
  testConnectionFailed: 'Test connection failed',
  online: 'Online',
  from: 'from',
  assignedVersion: 'Assigned version',
  lastUpdated: 'Last updated',
  notDefined: 'Not defined',
  version: 'version'
};

export const DatabaseVersion: FC<DatabaseVersionProps> = (props) => {
  const {
    id,
    className,
    style,
    dataTestId,
    translations = defaultTranslation,
    version,
    versions,
    error,
    lastUpdate,
    isLoading,
    isSeeDetails,
    onSeeDetails,
    assignedVersion,
    setAssignedVersion,
    warningMessage,
    online
  } = props;

  const {
    ref,
    isComponentVisible: showDropdown,
    setIsComponentVisible: setShowDropdown
  } = useComponentVisible(false);

  const onSelectVersion = (version: string) => {
    setShowDropdown(false);
    if (setAssignedVersion) {
      setAssignedVersion(version);
    }
  };

  const formattedVersions: DropdownItemProps[] = useMemo(() => {
    return versions?.map((version) => {
      return {
        value: version.value,
        label: `${translations.version} ${version.label}`
      };
    });
  }, [versions, translations?.version]);

  const isVersionsMatch: boolean = useMemo(() => {
    if (!assignedVersion || !version) {
      return true;
    }
    return assignedVersion == version;
  }, [assignedVersion, version]);

  return (
    <Card
      className={cn(
        className,
        isLoading ? styles['card--loading'] : styles.card
      )}
      style={style}
      id={id}
      data-testid={dataTestId}
    >
      {warningMessage && !isVersionsMatch && (
        <div className={styles['warning-icon']}>
          <Tooltip content={warningMessage}>
            <Icon name={'alert'} color={'warning'} size={'s'} />
          </Tooltip>
        </div>
      )}

      <div className={styles.header}>
        <Typography className={offsets['mr-8']} variant="h5">
          {translations.modelTreeVersions}
        </Typography>

        {isSeeDetails && (
          <div className={styles.details}>
            <Tooltip content={translations.seeDetails}>
              <Icon
                size="s"
                onClick={onSeeDetails && onSeeDetails}
                name="chevrons-right"
                dataTestId="details-icon"
              />
            </Tooltip>
          </div>
        )}
      </div>

      <div className={styles['scroll-container']}>
        <div className={styles.row}>
          <div>
            <Typography
              className={offsets['mb-4']}
              variant="system-heading"
              color={colors.gray40Color}
              element="div"
            >
              {translations.databaseVersion}
            </Typography>

            {isLoading ? (
              <SkeletonPlaceholder width={194} height={40} />
            ) : (
              <Typography variant={'h1'} element="div">
                {version || DASH}
              </Typography>
            )}
          </div>
        </div>

        <div className={offsets['mt-16']}>
          <Typography
            className={offsets['mb-4']}
            variant={'body-2'}
            element={'div'}
          >
            {translations.lastUpdated}
          </Typography>
          {isLoading ? (
            <SkeletonPlaceholder width={194} height={20} />
          ) : (
            <Typography variant={'body-1'} element={'div'}>
              {!lastUpdate?.version ? (
                translations.notDefined
              ) : (
                <>
                  {`${formatDate(lastUpdate?.date)}`}
                  {lastUpdate?.version &&
                    ` ${translations.from} v${lastUpdate?.version}`}
                </>
              )}
            </Typography>
          )}
        </div>

        <div className={styles.versions}>
          <Typography variant={'body-2'} element={'div'}>
            {translations.assignedVersion}
          </Typography>

          {isLoading ? (
            <SkeletonPlaceholder
              width={40}
              height={20}
              className={offsets['ml-6']}
            />
          ) : (
            <Typography
              variant={'body-1'}
              element={'div'}
              className={offsets['ml-6']}
            >
              {assignedVersion ? assignedVersion : translations.notDefined}
            </Typography>
          )}

          {formattedVersions?.length && !isLoading && (
            <div ref={ref} className={styles['versions-dropdown']}>
              <Icon
                name={'chevron-down'}
                size={'s'}
                onClick={() => setShowDropdown(!showDropdown)}
              />
              {showDropdown && (
                <div className={styles.dropdown}>
                  <Radio
                    onChange={onSelectVersion}
                    selected={assignedVersion}
                    align="vertical"
                    values={formattedVersions}
                  />
                </div>
              )}
            </div>
          )}
        </div>

        <>
          {isLoading ? (
            <SkeletonPlaceholder
              className={offsets['mt-16']}
              width={237}
              height={20}
            />
          ) : (
            <>
              {online && (
                <div className={cn(offsets['mt-16'], styles.row)}>
                  <Icon
                    name={'success'}
                    color={'success'}
                    size="s"
                    className={offsets['mr-8']}
                  />
                  <Typography variant="body-1" element="div">
                    {translations.online}
                  </Typography>
                </div>
              )}

              {error && (
                <>
                  <div className={cn(offsets['mt-16'], styles.row)}>
                    <Icon
                      name={'error'}
                      color={'error'}
                      size="s"
                      className={offsets['mr-8']}
                    />
                    <Typography variant="body-1" element="div">
                      {translations.testConnectionFailed}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      variant="body-2"
                      element="div"
                      className={offsets['mt-16']}
                    >
                      {translations.errorDetails}
                    </Typography>
                    <div
                      className={offsets['mt-16']}
                      dangerouslySetInnerHTML={{ __html: error }}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </>
      </div>
    </Card>
  );
};

DatabaseVersion.displayName = 'DatabaseVersion';
