import {
  Breadcrumbs,
  BreadcrumbsItem,
  Button,
  Col,
  DateIntervalType,
  DatePicker,
  Input,
  offsets,
  openStatusNotification,
  Row,
  Select,
  SelectItem,
  Textarea,
  Typography,
  Uploader
} from '@xq/ui-kit';
import React, { FC, Fragment, useEffect, useState } from 'react';
import styles from './CreateOrganization.module.scss';
import { useTranslation } from 'react-i18next';
import {
  CreateOrganizationService,
  CreateOrganizationServiceApi
} from '../CreateOrganization/create-organization-service';
import cn from 'classnames';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  getCurrentLanguage,
  getStatusNotificationTranslations,
  submitForm
} from '@services';
import { getRouteUrl, ROUTES } from '@router';
import { Organization } from './dataTypes';
import { FileRestrictionsDTOResponse } from '@xq/omni-gateway-frontend-client';

export const CreateOrganization: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const service: CreateOrganizationService = new CreateOrganizationServiceApi();

  const [name, setName] = useState<string | number>('');
  const [description, setDescription] = useState<string>('');
  const [agreementNumber, setAgreementNumber] = useState<string | number>('');
  const [agreementDate, setAgreementDate] = useState<Date>(null);
  const [manager, setManager] = useState<SelectItem>(null);
  const [currency, setCurrency] = useState<SelectItem>(null);
  const [country, setCountry] = useState<SelectItem>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [countries, setCountries] = useState<SelectItem[]>(null);
  const [currencies, setCurrencies] = useState<SelectItem[]>(null);
  const [managers, setManagers] = useState<SelectItem[]>(null);
  const [filesRestrictions, setFilesRestrictions] =
    useState<FileRestrictionsDTOResponse>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [clearFiles, setClearFiles] = useState<boolean>(false);
  const [reset, setReset] = useState<boolean>(false);

  async function fetchData() {
    try {
      const response = await service.fetchData();
      setCountries(response?.countries);
      setCurrencies(response?.currencies);
      setManagers(response?.managers);
      setFilesRestrictions(response?.filesRestrictions);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const isFieldsFilled = (): boolean => {
    return !(name && currency && country);
  };

  async function create() {
    setReset(true);
    setIsLoading(true);

    try {
      const organization: Organization = {
        name: String(name),
        description: String(description),
        agreementNumber: String(agreementNumber),
        agreementDate: agreementDate,
        managerUuid: manager?.value,
        currencyIso3: currency?.value,
        countryIso3: country?.value
      };

      const uuid = await service.create(organization);

      if (files?.length > 0) {
        await service.addOrganizationAgreementFiles(uuid, files);
        openStatusNotification({
          translations: getStatusNotificationTranslations(t),
          status: 200
        });
      } else {
        openStatusNotification({
          translations: getStatusNotificationTranslations(t),
          status: 200
        });
      }

      navigate(getRouteUrl(ROUTES.ORGANIZATIONS.ORGANIZATION, { id: uuid }));

      setName('');
      setDescription('');
      setCountry(null);
      setCurrency(null);
      setManager(null);
      setAgreementNumber('');
      setAgreementDate(null);
      setFiles(null);
      setClearFiles(true);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  function cancel() {
    navigate(getRouteUrl(ROUTES.ORGANIZATIONS.MAIN));
  }

  const breadcrumbs: BreadcrumbsItem[] = [
    {
      label: t(ROUTES.ORGANIZATIONS.MAIN),
      url: getRouteUrl(ROUTES.ORGANIZATIONS.MAIN)
    }
  ];

  return (
    <Fragment>
      <Row cols={10}>
        <Col col={10}>
          <Breadcrumbs
            NavLink={NavLink}
            className={'breadcrumbs'}
            items={breadcrumbs}
          />

          <Typography className={offsets['mb-40']} element="div" variant="h2">
            {t('organizations.createOrganization')}
          </Typography>
        </Col>
      </Row>

      <Row cols={10}>
        <Col col={8} md={4}>
          <form onSubmit={submitForm}>
            <Input
              required
              value={name}
              onChange={setName}
              className={offsets['mb-20']}
              label={t('common.name')}
            />
            <Textarea
              value={description}
              onChange={setDescription}
              className={offsets['mb-20']}
              label={t('common.description')}
            />

            <div className={cn(offsets['mb-40'], styles.block)}>
              <Select
                required
                items={countries}
                selected={country}
                onChange={setCountry}
                label={t('common.country')}
              />
              <Select
                required
                items={currencies}
                selected={currency}
                onChange={setCurrency}
                label={t('common.currency')}
              />
            </div>
            <Select
              className={offsets['mb-40']}
              items={managers}
              selected={manager}
              onChange={setManager}
              label={t('common.organizationManager')}
            />
            <div className={cn(offsets['mb-40'], styles.block)}>
              <Input
                value={agreementNumber}
                onChange={setAgreementNumber}
                label={t('organizations.agreementNumber')}
              />
              <DatePicker
                datePickerPlaceholder={t('organizations.agreementDate')}
                type="day"
                dateIntervalType={DateIntervalType.Date}
                locale={getCurrentLanguage()}
                datePickerValue={agreementDate}
                onDatePickerChange={setAgreementDate}
              />
            </div>

            <Uploader
              files={files}
              upload={setFiles}
              className={offsets['mb-40']}
              dragFileHereOrText={t('common.dragFileHereOr')}
              browseText={t('common.browse')}
              maximumUploadFileSizeText={t('common.maximumUploadFileSizeText')}
              reset={reset}
              setReset={setReset}
              clearFiles={clearFiles}
              setClearFiles={setClearFiles}
              maxSize={filesRestrictions?.maxByteSize}
              accept={filesRestrictions?.allowedMimeTypes}
            />

            <div>
              <Button
                buttonType={'submit'}
                onClick={create}
                isLoading={isLoading}
                disabled={isFieldsFilled()}
                className={offsets['mr-20']}
              >
                {t('common.create')}
              </Button>
              <Button onClick={cancel} type="secondary">
                {t('common.cancel')}
              </Button>
            </div>
          </form>
        </Col>
      </Row>
    </Fragment>
  );
};

CreateOrganization.displayName = 'CreateOrganization';
