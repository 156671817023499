import { SidemenuContext, SidemenuContextData } from '@context';
import {
  getBreadcrumbWithDropdown,
  getStatusNotificationTranslations,
  ORGANIZATION_SIDEMENUS
} from '@services';
import React, {
  FC,
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Organization.module.scss';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {
  Alert,
  Breadcrumbs,
  BreadcrumbsItem,
  Col,
  colors,
  common,
  ContactPerson,
  CountryIso3,
  Currency,
  DatabaseVersion,
  Invoices,
  LastActivities,
  Licenses,
  offsets,
  openStatusNotification,
  OrganizationInfo,
  Payments,
  Row,
  SkeletonPlaceholder,
  Typography,
  Users
} from '@xq/ui-kit';
import {
  OrganizationService,
  OrganizationServiceApi
} from '@pages/Organizations/Organization/organization-service';
import { OrganizationData } from './dataTypes';
import { getRouteUrl, ROUTES } from '@router';

export const Organization: FC = () => {
  const { t } = useTranslation();
  const params = useParams();
  const service: OrganizationService = new OrganizationServiceApi();
  const navigate = useNavigate();

  const [organization, setOrganization] = useState<OrganizationData>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isMTVersionUpdating, setIsMTVersionUpdating] =
    useState<boolean>(false);
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>('');
  const [versionToAssign, setVersionToAssign] = useState<string>(null);

  const sidemenuContext: SidemenuContextData = useContext(SidemenuContext);

  async function fetchData() {
    try {
      setIsLoading(true);
      const response: OrganizationData = await service.fetchData(params.id, t);
      setOrganization(response);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function setModeltreeVersion(version: string) {
    setIsAlertOpen(true);
    setVersionToAssign(version);
    setAlertText(
      `Are you sure you want to change <b>Assigned ModelTree Version</b> to <b>${version}</b>?`
    );
  }

  async function assignModeltreeVersion() {
    setIsMTVersionUpdating(true);

    try {
      await service.assignModeltreeVersion(params.id, versionToAssign);
      const updatedOrganization: OrganizationData = {
        ...organization,
        modeltreeInfo: {
          ...organization.modeltreeInfo,
          assignedAppVersion: versionToAssign
        }
      };
      setOrganization(updatedOrganization);
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200
      });
    } catch (error) {
      if (error.status !== 404) {
        openStatusNotification({
          translations: getStatusNotificationTranslations(t),
          status: error?.status,
          error: {
            details: error?.details,
            code: error?.error,
            message: error?.message
          }
        });
      }
    } finally {
      setIsMTVersionUpdating(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [params.id]);

  useEffect(() => {
    sidemenuContext.setActiveMenu(ORGANIZATION_SIDEMENUS.ORGANIZATION);
  }, []);

  const redirectToLicenses = () => {
    navigate(
      getRouteUrl(ROUTES.ORGANIZATIONS.LICENSES, {
        id: params?.id
      })
    );
  };

  const redirectToInvoices = () => {
    navigate(
      getRouteUrl(ROUTES.ORGANIZATIONS.INVOICES, {
        id: params?.id
      })
    );
  };

  const redirectToActivityLog = () => {
    navigate(
      getRouteUrl(ROUTES.ORGANIZATIONS.ACTIVITY_LOGS, {
        id: params?.id
      })
    );
  };

  const redirectToUsers = () => {
    navigate(
      getRouteUrl(ROUTES.ORGANIZATIONS.USERS, {
        id: params?.id
      })
    );
  };

  const redirectToContactPerson = (contactPersonId: string) => {
    navigate(
      getRouteUrl(ROUTES.ORGANIZATIONS.EDIT_CONTACT_PERSON, {
        id: params?.id,
        contactPersonId: contactPersonId
      })
    );
  };

  const redirectToDatabaseSettings = () => {
    navigate(
      getRouteUrl(ROUTES.ORGANIZATIONS.DATABASE_SETTINGS_MT, {
        id: params?.id
      })
    );
  };

  const redirectToOrganization = () => {
    navigate(
      getRouteUrl(ROUTES.ORGANIZATIONS.ORGANIZATION_INFO, {
        id: params?.id
      })
    );
  };

  /* breadcrumbs */
  const breadcrumbs: BreadcrumbsItem[] = useMemo(
    () => [
      {
        label: t(ROUTES.ORGANIZATIONS.MAIN),
        url: getRouteUrl(ROUTES.ORGANIZATIONS.MAIN)
      },
      getBreadcrumbWithDropdown(
        t,
        sidemenuContext,
        ROUTES.ORGANIZATIONS.ORGANIZATION,
        { id: params?.id }
      )
    ],
    [sidemenuContext, params?.id]
  );

  return (
    <Fragment>
      <Row cols={10}>
        <Col col={9}>
          <Breadcrumbs
            className={'breadcrumbs'}
            items={breadcrumbs}
            NavLink={NavLink}
          />
          <div className={offsets['mb-2']}>
            {!isLoading && (
              <Typography
                element="div"
                variant="h2"
                className={common['no-text-transform']}
              >
                {organization?.organizationInfo?.name}
              </Typography>
            )}
            {isLoading && <SkeletonPlaceholder width={160} height={40} />}
          </div>

          <Typography
            className={offsets['mb-20']}
            element="div"
            variant="body-4"
            color={colors.gray60Color}
          >
            {organization?.organizationInfo?.description}
          </Typography>

          <div className={styles['grid-1']}>
            <Invoices
              currency={organization?.invoicesState?.currencyIso3 as Currency}
              lastInvoiceDate={organization?.invoicesState?.lastInvoiceDate}
              nextInvoiceDate={organization?.invoicesState?.nextInvoiceDate}
              lastPayment={organization?.invoicesState?.lastPayment}
              nextPayment={organization?.invoicesState?.nextPayment}
              translations={{
                invoices: t('organizations.invoices'),
                lastInvoiceDate: t('uiKit.lastInvoiceDate'),
                nextInvoiceDate: t('uiKit.nextInvoiceDate'),
                lastPayment: t('uiKit.lastPayment'),
                nextPayment: t('uiKit.nextPayment'),
                estimatedMonthlyPayment: t('uiKit.estimatedMonthlyPayment'),
                invoicing: t('organizations.invoicing'),
                paymentMethod: t('uiKit.paymentMethod'),
                nextBillingDate: t('uiKit.nextBillingDate'),
                seeDetails: t('uiKit.seeDetails')
              }}
              isLoading={isLoading}
              onSeeDetails={redirectToInvoices}
              isSeeDetails={true}
            />
          </div>

          <div className={styles['grid-4']}>
            <Licenses
              licenses={organization?.licenses}
              translations={{
                seeDetails: t('uiKit.seeDetails'),
                license: t('common.license'),
                activeUsers: t('organizations.activeUsers'),
                activationDate: t('organizations.activationDate'),
                billingStartDate: t('organizations.billingStartDate'),
                expiryDate: t('uiKit.expiryDate'),
                licenses: t('common.licenses'),
                notDefined: t('common.notDefined'),
                trialVersion: t('uiKit.trialVersion'),
                invoicing: t('organizations.invoicing'),
                paymentMethod: t('organizations.paymentMethod'),
                unlimited: t('uiKit.unlimited')
              }}
              isLoading={isLoading}
              onSeeDetails={redirectToLicenses}
              isOmniView={true}
            />
          </div>

          <div className={styles['grid-3']}>
            <OrganizationInfo
              className={styles.info}
              organizationName={organization?.organizationInfo?.name}
              country={
                organization?.organizationInfo?.countryIso3 as CountryIso3
              }
              currency={organization?.organizationInfo?.currencyIso3}
              agreementNumber={organization?.organizationInfo?.agreementId}
              agreementDate={organization?.organizationInfo?.agreementDate}
              translations={{
                organizationInfoText: t('organizations.organizationInfo'),
                organizationText: t('organizations.organization'),
                countryText: t('common.country'),
                currencyText: t('common.currency'),
                agreementNumberText: t('uiKit.agreementNo'),
                agreementDateText: t('organizations.agreementDate'),
                copyText: t('uiKit.copy'),
                copiedText: t('uiKit.сopied'),
                seeDetails: t('uiKit.seeDetails')
              }}
              isLoading={isLoading}
              onSeeDetails={redirectToOrganization}
              isSeeDetails={true}
            />

            <Users
              className={styles.users}
              totalUsers={organization?.userStatistic?.totalUsers}
              activeUsers={organization?.userStatistic?.activeUsers}
              administrators={organization?.userStatistic?.administrators}
              externalUsers={organization?.userStatistic?.externalUsers}
              isLoading={isLoading}
              onClick={redirectToUsers}
              isSeeDetails={true}
              translations={{
                totalUsers: t('organizations.totalUsers'),
                activeUsers: t('organizations.activeUsers'),
                externalUsers: t('uiKit.externalUsers'),
                administrators: t('organizations.administrators'),
                users: t('common.users'),
                seeDetails: t('uiKit.seeDetails')
              }}
            />

            <DatabaseVersion
              className={styles.db}
              version={organization?.modeltreeInfo?.currentAppVersion}
              assignedVersion={organization?.modeltreeInfo?.assignedAppVersion}
              versions={organization?.modeltreeVersions}
              lastUpdate={{
                date: organization?.modeltreeInfo?.lastVersionUpdateDate,
                version: organization?.modeltreeInfo?.previousAppVersion
              }}
              error={organization?.modeltreeInfo?.errorDetails}
              isLoading={isMTVersionUpdating}
              onSeeDetails={redirectToDatabaseSettings}
              setAssignedVersion={setModeltreeVersion}
              online={organization?.modeltreeInfo?.online}
              isSeeDetails={true}
              warningMessage={"Client's ModelTree need to be updated"}
              translations={{
                seeDetails: t('uiKit.seeDetails'),
                modelTreeVersions: t('organizations.modelTreeVersions'),
                type: t('common.type'),
                databaseVersion: t('organizations.databaseVersion'),
                errorDetails: t('uiKit.errorDetails'),
                testConnectionFailed: t('organizations.testConnectionFailed'),
                online: t('organizations.online'),
                lastUpdated: t('uiKit.lastUpdated'),
                from: t('uiKit.from'),
                assignedVersion: t('organizations.assignedVersion'),
                notDefined: t('common.notDefined'),
                version: t('common.version')
              }}
            />
          </div>

          <div className={styles['grid-2']}>
            <Payments
              data={organization?.payments}
              currency={
                organization?.organizationInfo?.currencyIso3 as Currency
              }
              isLoading={isLoading}
              translations={{
                seeDetails: t('uiKit.seeDetails'),
                total: t('common.total'),
                payments: t('common.payments')
              }}
              onSeeDetails={redirectToInvoices}
            />

            <LastActivities
              lastActivities={organization?.lastActivities}
              isLoading={isLoading}
              translations={{
                seeDetails: t('uiKit.seeDetails'),
                lastActivities: t('uiKit.lastActivities'),
                application: t('uiKit.application'),
                dateTime: t('common.dateAndTime'),
                user: t('common.user'),
                notDefined: t('common.notDefined')
              }}
              onSeeDetails={redirectToActivityLog}
            />
          </div>

          <div className={styles['grid-3']}>
            {organization?.contactPersons?.length > 0 &&
              organization?.contactPersons?.map((contact) => {
                return (
                  <ContactPerson
                    key={contact?.uuid}
                    role={contact?.position}
                    name={contact?.name}
                    phoneNumber={contact?.phone}
                    email={contact?.email}
                    comments={contact?.comment}
                    isLoading={isLoading}
                    isEdit={false}
                    translations={{
                      seeDetails: t('uiKit.seeDetails'),
                      role: t('uiKit.role'),
                      name: t('common.name'),
                      phoneNumber: 'Phone number',
                      email: t('common.email'),
                      comments: t('uiKit.comments'),
                      delete: t('common.delete'),
                      edit: t('common.edit')
                    }}
                    onSeeDetails={() => redirectToContactPerson(contact?.uuid)}
                  />
                );
              })}
          </div>
        </Col>
      </Row>
      <Alert
        isOpen={isAlertOpen}
        onSubmit={assignModeltreeVersion}
        onClose={() => setIsAlertOpen(false)}
        cancelText={t('common.close')}
        submitText={t('organizations.change')}
      >
        <div dangerouslySetInnerHTML={{ __html: alertText }} />
      </Alert>
    </Fragment>
  );
};

Organization.displayName = 'Organization';
