import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { SkeletonPlaceholder } from '@components';

import styles from '@components/Map/Map.module.scss';

function MapLoader({
  className,
  relativeCover
}: {
  className?: string;
  relativeCover?: boolean;
}): ReactElement {
  return (
    <div
      className={classNames(styles.mapLoaderWrapper, className, {
        [styles.relativeCover]: relativeCover
      })}
    >
      <div className={styles.mapLoaderWrapperMain}>
        <SkeletonPlaceholder
          width={'100%'}
          height={relativeCover ? '100%' : 500}
        />
      </div>
    </div>
  );
}

export default MapLoader;
