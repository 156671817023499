import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const SliderIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.15164 1.88086C3.51891 1.88086 3.81664 2.17859 3.81664 2.54586V6.78828C3.81664 7.15555 3.51891 7.45328 3.15164 7.45328C2.78437 7.45328 2.48664 7.15555 2.48664 6.78828V2.54586C2.48664 2.17859 2.78437 1.88086 3.15164 1.88086ZM8.00009 1.88086C8.36736 1.88086 8.66509 2.17859 8.66509 2.54586V4.9114H9.81828C10.1855 4.9114 10.4833 5.20913 10.4833 5.5764C10.4833 5.94367 10.1855 6.2414 9.81828 6.2414H6.18191C5.81464 6.2414 5.51691 5.94367 5.51691 5.5764C5.51691 5.20913 5.81464 4.9114 6.18191 4.9114H7.33509V2.54586C7.33509 2.17859 7.63282 1.88086 8.00009 1.88086ZM12.8485 1.88086C13.2158 1.88086 13.5135 2.17859 13.5135 2.54586V8.0004C13.5135 8.36767 13.2158 8.6654 12.8485 8.6654C12.4813 8.6654 12.1835 8.36767 12.1835 8.0004V2.54586C12.1835 2.17859 12.4813 1.88086 12.8485 1.88086ZM8.00009 7.3354C8.36736 7.3354 8.66509 7.63314 8.66509 8.0004V13.455C8.66509 13.8222 8.36736 14.12 8.00009 14.12C7.63282 14.12 7.33509 13.8222 7.33509 13.455V8.0004C7.33509 7.63314 7.63282 7.3354 8.00009 7.3354ZM0.668457 9.21276C0.668457 8.84549 0.966188 8.54776 1.33346 8.54776H4.96982C5.33709 8.54776 5.63482 8.84549 5.63482 9.21276C5.63482 9.58003 5.33709 9.87776 4.96982 9.87776H3.81664V13.4552C3.81664 13.8225 3.51891 14.1202 3.15164 14.1202C2.78437 14.1202 2.48664 13.8225 2.48664 13.4552V9.87776H1.33346C0.966188 9.87776 0.668457 9.58003 0.668457 9.21276ZM10.3654 10.4242C10.3654 10.057 10.6631 9.75923 11.0304 9.75923H14.6667C15.034 9.75923 15.3317 10.057 15.3317 10.4242C15.3317 10.7915 15.034 11.0892 14.6667 11.0892H13.5135V13.4547C13.5135 13.822 13.2158 14.1197 12.8485 14.1197C12.4813 14.1197 12.1835 13.822 12.1835 13.4547V11.0892H11.0304C10.6631 11.0892 10.3654 10.7915 10.3654 10.4242Z"
      fill="#121212"
    />
  </SvgIcon>
);

SliderIcon.displayName = 'SliderIcon';
