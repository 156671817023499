import { SidemenuContext, SidemenuContextData } from '@context';
import {
  formatDateToUTCMidnight,
  getBreadcrumbWithDropdown,
  getCurrentLanguage,
  getStatusNotificationTranslations
} from '@services';
import React, {
  FC,
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import hash from 'object-hash';
import { useTranslation } from 'react-i18next';
import styles from './Pricing.module.scss';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {
  Alert,
  Breadcrumbs,
  BreadcrumbsItem,
  Button,
  Col,
  Currency,
  DateIntervalType,
  DatePicker,
  EmptyState,
  formatDate,
  Icon,
  offsets,
  openStatusNotification,
  PriceRuleType,
  PricingData,
  PricingList,
  Row,
  Select,
  SelectItem,
  Tooltip,
  Typography
} from '@xq/ui-kit';
import { getRouteUrl, ROUTES } from '@router';
import { PricingItem } from './dataTypes';
import { PricingService, PricingServiceApi } from './pricing-service';
import { methodsAndInvoicingOptions } from './constants';
import {
  filterChangedPriceLists,
  generateInitialPricingData,
  getInitialPriceItem,
  getPricePerUserMonthly,
  getPricingForList,
  getPricingInformationForBackend,
  initPricingInformationObject,
  isPricingValid,
  scrollPricingToRight
} from '@pages/Organizations/Pricing/utils';
import { DASH } from '@constants';
import { v4 as uuidv4 } from 'uuid';

export const Pricing: FC = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const service: PricingService = new PricingServiceApi();

  const [initialPricingInformation, setInitialPricingInformation] =
    useState<PricingItem[]>(null);
  const [pricingInformation, setPricingInformation] = useState<PricingItem[]>(
    []
  );
  const [currency, setCurrency] = useState<Currency>(null);
  const [isPricingChanged, setIsPricingChanged] = useState<boolean>(false);
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);

  const initialPricingInformationHash = useMemo(() => {
    return hash(initialPricingInformation);
  }, [initialPricingInformation]);

  /** Api calls */

  async function fetchData() {
    try {
      const res = await service.fetchData(params.id, params.licenseId);

      if (res?.priceLists && res?.priceLists?.length > 0) {
        const clone: PricingItem[] = JSON.parse(
          JSON.stringify(res?.priceLists || [])
        );
        setInitialPricingInformation(generateInitialPricingData(clone || []));
      } else {
        setInitialPricingInformation([]);
      }

      setPricingInformation(
        initPricingInformationObject(res?.priceLists || [])
      );
      setCurrency(res?.currency);
      setIsEmpty(!res?.priceLists || res?.priceLists.length === 0);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (pricingInformation) {
      const newHash = hash(getPricingInformationForBackend(pricingInformation));
      setIsPricingChanged(newHash !== initialPricingInformationHash);
    }
  }, [pricingInformation, initialPricingInformationHash]);

  async function save() {
    setIsLoading(true);

    const pricingInformationForBackend = getPricingInformationForBackend(
      pricingInformation?.map((pricing) => {
        if (
          !initialPricingInformation?.find((el) => el.uuid === pricing.uuid)
        ) {
          pricing.uuid = null;
        }
        return pricing;
      })
    );

    const filteredPriceLists = filterChangedPriceLists(
      pricingInformationForBackend,
      initialPricingInformation
    );

    try {
      await service.save(params.id, params.licenseId, filteredPriceLists);
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200
      });
      /** update because new price lists now have uuids */
      await fetchData();
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function deletePricing(pricingEl: PricingItem) {
    if (!pricingEl?.updateIsAllowed) {
      return;
    }

    const deletePricingLocally = () => {
      if (pricingInformation?.length === 1) {
        setPricingInformation([getInitialPriceItem(uuidv4())]);
      } else {
        const newPricing = pricingInformation?.filter(
          (el) => pricingEl.uuid !== el.uuid
        );
        setPricingInformation(newPricing);
      }
    };

    if (!initialPricingInformation?.find((el) => el.uuid === pricingEl.uuid)) {
      deletePricingLocally();
      return;
    }

    setIsLoading(true);
    try {
      await service.deletePriceList(
        params.id,
        params.licenseId,
        pricingEl.uuid
      );
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200
      });
      deletePricingLocally();
      const pricingInformationForBackend = getPricingInformationForBackend(
        pricingInformation?.filter((el) => el.uuid !== pricingEl.uuid)
      );
      if (pricingInformation && pricingInformation.length > 0) {
        const clone: PricingItem[] = JSON.parse(
          JSON.stringify(pricingInformationForBackend || [])
        );
        setInitialPricingInformation(generateInitialPricingData(clone || []));
      } else {
        setInitialPricingInformation([]);
      }
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  const cancel = () => {
    setPricingInformation(
      initPricingInformationObject(initialPricingInformation)
    );
  };

  const onCancelClick = () => {
    if (isPricingChanged) {
      setIsAlertOpen(true);
    } else {
      navigate(
        getRouteUrl(ROUTES.ORGANIZATIONS.LICENSE, {
          id: params.id,
          licenseId: params.licenseId
        })
      );
    }
  };

  useEffect(() => {
    scrollPricingToRight();
  }, [pricingInformation?.length]);

  const isPricingDataValid: boolean = useMemo(() => {
    return isPricingValid(pricingInformation);
  }, [pricingInformation]);

  /** Change data functions */

  const changeInvoicingAndMethod = (
    pricingListUuid: string,
    item: SelectItem
  ) => {
    const newPricingInformation = pricingInformation?.map((el) => {
      if (el.uuid === pricingListUuid) {
        if (item?.value) {
          const strings = item.value?.split('_');
          const [billingFrequency, paymentMethod] = strings;
          return {
            ...el,
            billingFrequency: billingFrequency,
            paymentMethod: paymentMethod,
            invoicingAndMethod: item
          };
        }
        return {
          ...el,
          billingFrequency: null,
          paymentMethod: null,
          invoicingAndMethod: item
        };
      }
      return el;
    });
    setPricingInformation(newPricingInformation);
  };

  const onChangePricing = (pricingListUuid: string, pricing: PricingData[]) => {
    const newPricingInformation = pricingInformation?.map((el) => {
      if (el.uuid === pricingListUuid) {
        const pricePerUserMonthly = getPricePerUserMonthly(
          el.licensePriceListRules
        );

        return {
          ...el,
          licensePriceListRules: [...pricing, { price: pricePerUserMonthly }]
        };
      }
      return el;
    });
    setPricingInformation(newPricingInformation);
  };

  const onChangePricePerUser = (
    pricingListUuid: string,
    pricePerUser: number
  ) => {
    const newPricingInformation = pricingInformation?.map((el) => {
      if (el.uuid === pricingListUuid) {
        const pricingForList = getPricingForList(el.licensePriceListRules);
        return {
          ...el,
          licensePriceListRules: [...pricingForList, { price: pricePerUser }]
        };
      }
      return el;
    });
    setPricingInformation(newPricingInformation);
  };

  const onChangePriceRuleType = (
    pricingListUuid: string,
    priceRuleType: PriceRuleType
  ) => {
    const newPricingInformation = pricingInformation?.map((el) => {
      if (el.uuid === pricingListUuid) {
        return { ...el, priceRuleType };
      }
      return el;
    });
    setPricingInformation(newPricingInformation);
  };

  const setBillingStartDate = (pricingListUuid: string, date: Date) => {
    const newPricingInformation = pricingInformation?.map((el) => {
      if (el.uuid === pricingListUuid) {
        return { ...el, startAt: date ? formatDateToUTCMidnight(date) : null };
      }
      return el;
    });
    setPricingInformation(newPricingInformation);
  };

  const addPricingItem = () => {
    setPricingInformation([
      ...pricingInformation,
      getInitialPriceItem(uuidv4())
    ]);
  };

  const deleteAlertText = (pricing: PricingItem) => {
    return `${t('alerts.areYouSureYouWantToDelete')} ${t('common.pricing')} ${
      pricing?.startAt ? formatDate(pricing?.startAt) : ''
    }?`;
  };

  /** Sidemenu */
  const sidemenuContext: SidemenuContextData = useContext(SidemenuContext);

  useEffect(() => {
    sidemenuContext.setActiveMenu(
      `licenses-${sidemenuContext?.licenses?.find(
        (el) => el?.uuid === params.licenseId
      )?.uuid}`
    );
  }, [sidemenuContext, params?.licenseId]);

  const licenseName = useMemo(() => {
    return sidemenuContext?.licenses?.find(
      (license) => license.uuid === params.licenseId
    )?.title;
  }, [sidemenuContext?.licenses, params.licenseId]);

  /** Breadcrumbs */
  const breadcrumbs: BreadcrumbsItem[] = useMemo(
    () => [
      {
        label: t(ROUTES.ORGANIZATIONS.MAIN),
        url: getRouteUrl(ROUTES.ORGANIZATIONS.MAIN)
      },
      getBreadcrumbWithDropdown(
        t,
        sidemenuContext,
        ROUTES.ORGANIZATIONS.LICENSES,
        { id: params.id }
      ),
      {
        label: t(ROUTES.ORGANIZATIONS.LICENSES),
        url: getRouteUrl(ROUTES.ORGANIZATIONS.LICENSES, { id: params.id })
      },
      {
        label: licenseName,
        url: getRouteUrl(ROUTES.ORGANIZATIONS.LICENSE, {
          id: params.id,
          licenseId: params.licenseId
        })
      }
    ],
    [licenseName, sidemenuContext, params.id, params.licenseId]
  );

  return (
    <Fragment>
      <Row cols={10}>
        <Breadcrumbs
          NavLink={NavLink}
          className={'breadcrumbs'}
          items={breadcrumbs}
        />
        <Col col={9} className={styles.heading}>
          <Typography element="div" variant="h2">
            {t('common.pricing')}
          </Typography>
          {!isEmpty && (
            <div className={styles['heading-buttons']}>
              <Button
                type="primary"
                icon="user-plus"
                onClick={save}
                isLoading={isLoading}
                disabled={!isPricingChanged || !isPricingDataValid}
                className={offsets['mr-20']}
              >
                {t('common.save')}
              </Button>
              <Button
                type="secondary"
                icon="user-plus"
                disabled={isLoading}
                onClick={onCancelClick}
              >
                {t('common.cancel')}
              </Button>
            </div>
          )}
        </Col>
      </Row>

      {!isEmpty && (
        <Row cols={10} style={{ marginRight: 0 }}>
          <div className={styles.grid} id={'pricingBlock'}>
            {pricingInformation?.map((pricingEl, key) => {
              return (
                <div key={key} className={styles.column}>
                  <div className={styles['datepicker-wrap']}>
                    <DatePicker
                      className={styles.datepicker}
                      datePickerPlaceholder={t(
                        'organizations.billingStartDate'
                      )}
                      type="day"
                      dateIntervalType={DateIntervalType.Date}
                      locale={getCurrentLanguage()}
                      datePickerValue={
                        pricingEl?.startAt ? new Date(pricingEl.startAt) : null
                      }
                      onDatePickerChange={(date: Date) =>
                        setBillingStartDate(pricingEl.uuid, date)
                      }
                      isUtc={true}
                      disabled={!pricingEl?.updateIsAllowed || isLoading}
                    />
                    {!pricingEl?.updateIsAllowed && (
                      <Tooltip
                        className={offsets['ml-20']}
                        type="bottom"
                        // todo add localization?
                        content={
                          'If you want to change current price, please remove all invoices for this price'
                        }
                      >
                        <Icon size="s" name="info" />
                      </Tooltip>
                    )}
                  </div>

                  <div className={styles.select}>
                    <Select
                      label={`${t('organizations.invoicing')} / ${t(
                        'organizations.method'
                      )}`}
                      items={methodsAndInvoicingOptions}
                      selected={pricingEl?.invoicingAndMethod}
                      onChange={(value: SelectItem) =>
                        changeInvoicingAndMethod(pricingEl.uuid, value)
                      }
                      disabled={!pricingEl?.updateIsAllowed || isLoading}
                    />
                  </div>

                  {pricingEl?.paymentMethod &&
                    pricingEl?.billingFrequency &&
                    pricingEl?.startAt && (
                      <div className={styles.pricing}>
                        <PricingList
                          pricing={getPricingForList(
                            pricingEl?.licensePriceListRules
                          )}
                          onChangePricing={(pricing) =>
                            onChangePricing(pricingEl.uuid, pricing)
                          }
                          currency={currency}
                          pricePerUser={getPricePerUserMonthly(
                            pricingEl?.licensePriceListRules
                          )}
                          onChangePricePerUser={(price) =>
                            onChangePricePerUser(pricingEl.uuid, price)
                          }
                          priceRuleType={pricingEl?.priceRuleType}
                          onChangePriceRuleType={(priceRuleType) =>
                            onChangePriceRuleType(pricingEl.uuid, priceRuleType)
                          }
                          prevPricing={
                            key > 0 &&
                            getPricingForList(
                              pricingInformation[key - 1]?.licensePriceListRules
                            )
                          }
                          prevPricePerUser={
                            key > 0 &&
                            getPricePerUserMonthly(
                              pricingInformation[key - 1]?.licensePriceListRules
                            )
                          }
                          prevPriceRuleType={
                            key > 0
                              ? pricingInformation[key - 1].priceRuleType
                              : null
                          }
                          disabled={!pricingEl?.updateIsAllowed || isLoading}
                          onDelete={() => deletePricing(pricingEl)}
                          deleteAlertText={deleteAlertText(pricingEl)}
                          organizationName={
                            sidemenuContext?.currentOrganization?.name
                          }
                          billingStartDate={
                            pricingEl?.startAt
                              ? pricingEl?.startAt.toISOString()
                              : DASH
                          }
                          licenseName={licenseName}
                          translations={{
                            delete: t('common.delete'),
                            cancel: t('common.cancel'),
                            submit: t('common.submit'),
                            users: t('common.users'),
                            preview: t('common.preview'),
                            priceMonthly: t('uiKit.priceMonthly'),
                            pricePerUserMonthly: t('uiKit.pricePerUserMonthly'),
                            pricingPreview: t('uiKit.pricingPreview'),
                            organization: t('organizations.organization'),
                            license: t('common.license'),
                            billingStartDate: t(
                              'organizations.billingStartDate'
                            )
                          }}
                        />
                      </div>
                    )}
                </div>
              );
            })}
            <Button
              onClick={addPricingItem}
              className={styles['add-column']}
              type="fourth"
              icon={'plus'}
              disabled={isLoading}
            >
              {t('organizations.addNextBillingStartDate')}
            </Button>
          </div>
        </Row>
      )}
      {isEmpty && (
        <EmptyState
          heading={t('uiKit.oopsItIsEmpty')}
          description={t('alerts.looksLikeTheLicenseHasNoPrice')}
          onClick={() => setIsEmpty(false)}
          buttonText={t('common.create')}
          buttonIcon={'dollar-sign'}
          isCentered={true}
        />
      )}
      <Alert
        isOpen={isAlertOpen}
        onSubmit={() => cancel()}
        onClose={() => setIsAlertOpen(false)}
        cancelText={t('common.cancel')}
        submitText={t('common.submit')}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: t('alerts.areYouSureYouWantToClearAllChanges')
          }}
        />
      </Alert>
    </Fragment>
  );
};

Pricing.displayName = 'Pricing';
