export const getUniqueYears = (timestamps: number[]): number[] => {
  const yearsSet = new Set<number>();

  // Convert each timestamp to a year and add it to the set
  for (const timestamp of timestamps) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    // Convert year to UNIX timestamp and add it to the set
    yearsSet.add(new Date(year, 0, 1).getTime());
  }

  // Convert the set back to an array and return it
  return Array.from(yearsSet);
};

export const searchByKey = (
  data: Array<Record<string, string>> | string | number,
  key: string
) => {
  if (typeof data !== 'object') {
    return '-';
  }

  const found = data.find((item) => key in item);
  if (found) {
    return found[key];
  }
  return '-';
};

export const clearFormattedValueLabel = (label: string | number) => {
  if (typeof label === 'string') {
    const formattedLabel = label
      .split('=')[0]
      .replace('_formattedValue', '')
      .trim();
    return formattedLabel;
  } else {
    return label;
  }
};
