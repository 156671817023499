import React, {
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Alert,
  changeSortDirection,
  Col,
  downloadFileByLink,
  EmptyState,
  offsets,
  openStatusNotification,
  Row,
  ScrollTopButton,
  Search,
  SortDirection,
  Table,
  TableAction,
  TableColumn,
  TableRow,
  Typography
} from '@xq/ui-kit';
import { ReleasesService, ReleasesServiceApi } from './releases-service';
import {
  checkEmptyState,
  checkNoMoreData,
  debounce,
  getStatusNotificationTranslations
} from '@services';
import { SidemenuContext, SidemenuContextData } from '@context';
import { ListMetaInfo, ListParams } from 'interfaces';
import { SORT_DIRECTIONS } from '@constants';
import { createReleasesColumns, createReleasesRows } from './utils';
import { useDebounce } from '@hooks';
import { config } from '@config';
import { ReleasesItem } from '@pages/ModelTree';
import { getRouteUrl, ROUTES } from '@router';

export const Releases: FC = () => {
  const service: ReleasesService = new ReleasesServiceApi();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  /** Tables and base information  */
  const [releases, setReleases] = useState<ReleasesItem[]>([]);
  const [releasesMeta, setReleasesMeta] = useState<ListMetaInfo>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [noResults, setNoResults] = useState<boolean>(false);
  const [sortOrder, setSortOrder] = useState<SortDirection>(
    SORT_DIRECTIONS.DESC as SortDirection
  );
  const [sortBy, setSortBy] = useState<string>('version');
  const [noMoreData, setNoMoreData] = useState<boolean>(false);
  const [isEmptyState, setIsEmptyState] = useState<boolean>(false);

  const [searchValue, setSearchValue] = useState<string>(
    searchParams.get('search') || ''
  );
  const debouncedSearchValue = useDebounce(searchValue, config.debounceDelay);

  /** values to alert */
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  const [submitText, setSubmitText] = useState<string>('');
  const [alertText, setAlertText] = useState<string>('');
  const [alertSubmitFunction, setAlertSubmitFunction] = useState<() => void>();

  /* Table */
  const releasesColumns: TableColumn[] = createReleasesColumns(t);

  const releasesRows = useMemo(() => {
    return createReleasesRows(t, releases);
  }, [releases]);

  const actions: TableAction[] = [
    {
      key: 'edit',
      title: t('common.edit'),
      icon: 'tool',
      onClick: redirectToEditRelease
    },
    {
      key: 'download',
      title: t('common.download'),
      icon: 'download',
      onClick: downloadRelease
    },
    {
      key: 'publish',
      title: t('modelTree.publish'),
      icon: 'arrow-up',
      onClick: publishReleaseSubmit
    },
    {
      key: 'delete',
      title: t('common.delete'),
      icon: 'trash',
      onClick: deleteReleaseSubmit
    }
  ];

  const filterActionsForRow = (actions: TableAction[], row: TableRow) => {
    if (!row?.data?.publishDate) {
      return actions;
    }

    return actions?.map((action) =>
      action.key === 'publish' || action.key === 'delete'
        ? { ...action, disabled: true }
        : action
    );
  };

  function deleteReleaseSubmit(release: TableRow) {
    const releaseInfo = releases?.find((el) => el.uuid === release.id);
    setAlertText(
      `${t(
        'alerts.areYouSureYouWantToDelete'
      )} <span style='text-transform: lowercase'>${t(
        'modelTree.releaseVersion'
      )}</span> <b>${releaseInfo?.releaseVersion}</b>?`
    );
    setSubmitText(t('common.delete'));
    setAlertSubmitFunction(() => () => deleteRelease(release));
    setIsAlertOpen(true);
  }

  async function deleteRelease(release: TableRow) {
    try {
      await service.deleteRelease(Number(release.id));
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200,
        message: t('notifications.successfullyDeleted')
      });
      const newReleases = releases?.filter((el) => release.id !== el.uuid);
      setReleases(newReleases);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  async function downloadRelease(row: TableRow) {
    try {
      const url = await service.getDownloadReleaseFileUrl(Number(row.id));
      await downloadFileByLink(url);
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200,
        message: `${t('modelTree.buildId')} ${row.id} ${t(
          'notifications.isDownloading'
        )}`
      });
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  function publishReleaseSubmit(release: TableRow) {
    const releaseInfo = releases?.find((el) => el.uuid === release.id);
    setAlertText(
      `${t(
        'alerts.areYouSureYouWantToPublish'
      )} <span style='text-transform: lowercase'>${t(
        'modelTree.releaseVersion'
      )}</span> <b>${releaseInfo?.releaseVersion}</b>?`
    );
    setSubmitText(t('modelTree.publish'));
    setAlertSubmitFunction(() => () => publishRelease(release));
    setIsAlertOpen(true);
  }

  async function publishRelease(release: TableRow) {
    try {
      await service.publishRelease(Number(release.id));
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200
      });

      const newReleases = releases?.map((el) => {
        if (release.id === el.uuid) {
          return { ...el, publishDate: new Date() };
        }
        return el;
      });
      setReleases(newReleases);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  function redirectToEditRelease(row: TableRow) {
    navigate(getRouteUrl(ROUTES.MODEL_TREE.EDIT_RELEASE, { buildId: row.id }));
  }

  useEffect(() => {
    fetchReleases({
      searchValue: debouncedSearchValue,
      sortOrder: sortOrder,
      sortBy: sortBy
    });
  }, [sortOrder, sortBy, debouncedSearchValue]);

  const updateSearchParams = useCallback(
    debounce((newValue) => {
      if (newValue) {
        searchParams.set('search', newValue);
      } else {
        searchParams.delete('search');
      }
      setSearchParams(searchParams);
    }, config.debounceDelay),
    [searchParams, setSearchParams]
  );

  useEffect(() => {
    const searchParamValue = searchParams.get('search') || '';
    setSearchValue(searchParamValue);
  }, [searchParams]);

  const handleSearchValue = (newValue: string) => {
    setSearchValue(newValue);
    updateSearchParams(newValue);
  };

  async function fetchReleases(queryParams?: ListParams) {
    setIsLoading(true);
    try {
      const response = await service.fetchReleases(queryParams);

      const hasResults = !response.items || response?.items?.length === 0;
      setNoResults(hasResults);
      setReleases(response?.items);
      checkNoMoreData(
        response?.items?.length,
        response?.meta?.totalItems,
        setNoMoreData
      );
      checkEmptyState({ setIsEmptyState, meta: response?.meta, searchValue });
      setReleasesMeta(response?.meta);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function loadMoreReleases() {
    try {
      if (noMoreData) {
        return;
      }

      const response = await service.fetchReleases({
        page: ++releasesMeta.currentPage,
        searchValue: debouncedSearchValue,
        sortOrder: sortOrder,
        sortBy: sortBy
      });

      const { items } = response;
      checkNoMoreData(items?.length, response?.meta?.totalItems, setNoMoreData);
      setReleases([...releases, ...items]);
      setReleasesMeta(response?.meta);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  function updateSortParams(key: string) {
    let updatedSortBy = sortBy;
    let updatedSortOrder = sortOrder;

    if (key === sortBy) {
      updatedSortOrder = changeSortDirection(sortOrder);
      setSortOrder(updatedSortOrder);
    } else {
      updatedSortBy = key;
      setSortBy(updatedSortBy);
    }
  }

  /** Sidemenu */
  const sidemenuContext: SidemenuContextData = useContext(SidemenuContext);

  useEffect(() => {
    sidemenuContext.setActiveMenu(ROUTES.MODEL_TREE.RELEASES);
  }, [sidemenuContext]);

  return (
    <Fragment>
      <Row cols={10}>
        <Col col={9} className={'heading'}>
          <Typography element="div" variant="h2">
            {t('routes.modelTree.releases')}
          </Typography>
        </Col>
      </Row>

      <Row cols={10} style={{ marginRight: 0 }}>
        {!isLoading && isEmptyState && (
          <EmptyState
            heading={t('uiKit.oopsItIsEmpty')}
            description={t('alerts.looksLikeNoOneCame')}
            isCentered={true}
          />
        )}
      </Row>

      {!isEmptyState && (
        <Row cols={10}>
          <Col col={6} lg={3} className={offsets['mb-20']}>
            <Search
              placeholder={t('modelTree.searchByBuildIdOrReleaseVersion')}
              value={searchValue}
              onChange={handleSearchValue}
              disabled={isLoading}
            />
          </Col>

          <Col col={9}>
            <Table
              isLoading={isLoading}
              className={offsets['mb-40']}
              rows={releasesRows}
              columns={releasesColumns}
              noMoreData={noMoreData}
              noMoreItemsText={t('common.noMoreItems')}
              onLoading={loadMoreReleases}
              maxHeight="640px"
              minWidth={800}
              onSort={updateSortParams}
              sortOrder={sortOrder}
              sortBy={sortBy}
              noResults={noResults}
              noResultsText={t('common.noResults')}
              actions={actions}
              filterActionsForRow={filterActionsForRow}
            />
          </Col>
        </Row>
      )}

      <Alert
        isOpen={isAlertOpen}
        onSubmit={() => alertSubmitFunction()}
        onClose={() => setIsAlertOpen(false)}
        cancelText={t('common.cancel')}
        submitText={submitText}
      >
        <div dangerouslySetInnerHTML={{ __html: alertText }} />
      </Alert>

      <ScrollTopButton />
    </Fragment>
  );
};

Releases.displayName = 'Releases';
