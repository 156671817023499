import { ComponentProps, SvgIconColors, SvgIconSize } from '@components';
import {
  ActivityIcon,
  AlertIcon,
  ArrowRightIcon,
  ArrowUpIcon,
  BellAvailableIcon,
  BellIcon,
  BookUploadIcon,
  BreakIcon,
  BriefcaseIcon,
  BuildIcon,
  BurgerIcon,
  CalendarIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronsLeftIcon,
  ChevronsRightIcon,
  ChevronUpIcon,
  ClipboardIcon,
  CloseIcon,
  CloudIcon,
  CloudPrivateIcon,
  CloudPublicIcon,
  CopyIcon,
  CornerDownLeftIcon,
  CreditcardIcon,
  CreditcardPlusIcon,
  DatabaseIcon,
  DictionariesIcon,
  DollarSignIcon,
  DownloadIcon,
  EditIcon,
  ErrorIcon,
  EyeCloseIcon,
  EyeOpenIcon,
  FullscreenIcon,
  HomeIcon,
  InboxIcon,
  InfoIcon,
  InvoiceDetailsIcon,
  KeyIcon,
  LicensesAddIcon,
  LicensesFundyIcon,
  LicensesIcon,
  LicensesModelTreeIcon,
  Link2Icon,
  LinkIcon,
  LockIcon,
  LogoAccountIcon,
  LogoAccountIconDisabled,
  LogoAdministrationIcon,
  LogoAdministrationIconDisabled,
  LogoExquanceIcon,
  LogoExquanceIconDisabled,
  LogoFundyIcon,
  LogoFundyIconDisabled,
  LogoInsightsIcon,
  LogoInsightsIconDisabled,
  LogoModelTreeIcon,
  LogoModelTreeIconDisabled,
  LogoOmniIcon,
  LogoOmniIconDisabled,
  MapIcon,
  MapIconFilled,
  MinimizeMapIcon,
  MinusIcon,
  MoreIcon,
  NumberIcon,
  PaperclipIcon,
  PauseIcon,
  PercentIcon,
  PlayIcon,
  PlusIcon,
  ReleaseIcon,
  RepeatIcon,
  SearchIcon,
  SendIcon,
  SettingsIcon,
  ShieldIcon,
  SignInIcon,
  SignOutIcon,
  SliderIcon,
  SortLessIcon,
  SortMoreIcon,
  SpinnerIcon,
  StatusIcon,
  SuccessIcon,
  SwitcherIcon,
  ToolIcon,
  TrashIcon,
  UnlockIcon,
  UpdateIcon,
  UploadIcon,
  UserCheckIcon,
  UserIcon,
  UserMinusIcon,
  UserPlusIcon,
  UsersIcon,
  VisualTourChevronLeftIcon,
  VisualTourChevronRightIcon,
  ZapIcon
} from '@componentsIcons';
import classNames from 'classnames';
import React, { FC } from 'react';
import styles from './Icon.module.scss';
import { IconNames } from './dataTypes';

export interface IconProps extends ComponentProps {
  name: IconNames;
  color?: SvgIconColors;
  size?: SvgIconSize;

  onClick?(): void;
}

export const Icon: FC<IconProps> = (props) => {
  const {
    dataTestId,
    name,
    className,
    color,
    id,
    size = 'l',
    style,
    onClick
  } = props;
  const getIconByName = () => {
    const iconProps = { size, color, onClick, dataTestId };

    switch (name) {
      case 'activity': {
        return <ActivityIcon {...iconProps} />;
      }
      case 'alert': {
        return <AlertIcon {...iconProps} />;
      }
      case 'arrow-right': {
        return <ArrowRightIcon {...iconProps} />;
      }
      case 'arrow-up': {
        return <ArrowUpIcon {...iconProps} />;
      }
      case 'bell-available': {
        return <BellAvailableIcon {...iconProps} />;
      }
      case 'bell': {
        return <BellIcon {...iconProps} />;
      }
      case 'book-upload': {
        return <BookUploadIcon {...iconProps} />;
      }
      case 'briefcase': {
        return <BriefcaseIcon {...iconProps} />;
      }
      case 'build': {
        return <BuildIcon {...iconProps} />;
      }
      case 'burger': {
        return <BurgerIcon {...iconProps} />;
      }
      case 'calendar': {
        return <CalendarIcon {...iconProps} />;
      }
      case 'check': {
        return <CheckIcon {...iconProps} />;
      }
      case 'chevron-down': {
        return <ChevronDownIcon {...iconProps} />;
      }
      case 'chevron-left': {
        return <ChevronLeftIcon {...iconProps} />;
      }
      case 'chevron-right': {
        return <ChevronRightIcon {...iconProps} />;
      }
      case 'chevrons-left': {
        return <ChevronsLeftIcon {...iconProps} />;
      }
      case 'chevrons-right': {
        return <ChevronsRightIcon {...iconProps} />;
      }
      case 'chevron-up': {
        return <ChevronUpIcon {...iconProps} />;
      }
      case 'clipboard': {
        return <ClipboardIcon {...iconProps} />;
      }
      case 'close': {
        return <CloseIcon {...iconProps} />;
      }
      case 'cloud': {
        return <CloudIcon {...iconProps} />;
      }
      case 'cloud-private': {
        return <CloudPrivateIcon {...iconProps} />;
      }
      case 'cloud-public': {
        return <CloudPublicIcon {...iconProps} />;
      }
      case 'copy': {
        return <CopyIcon {...iconProps} />;
      }
      case 'corner-down-left': {
        return <CornerDownLeftIcon {...iconProps} />;
      }
      case 'creditcard': {
        return <CreditcardIcon {...iconProps} />;
      }
      case 'creditcard-plus': {
        return <CreditcardPlusIcon {...iconProps} />;
      }
      case 'database': {
        return <DatabaseIcon {...iconProps} />;
      }
      case 'dictionaries': {
        return <DictionariesIcon {...iconProps} />;
      }
      case 'dollar-sign': {
        return <DollarSignIcon {...iconProps} />;
      }
      case 'download': {
        return <DownloadIcon {...iconProps} />;
      }
      case 'edit': {
        return <EditIcon {...iconProps} />;
      }
      case 'error': {
        return <ErrorIcon {...iconProps} />;
      }
      case 'fullscreen': {
        return <FullscreenIcon {...iconProps} />;
      }
      case 'eye-open': {
        return <EyeOpenIcon {...iconProps} />;
      }
      case 'eye-close': {
        return <EyeCloseIcon {...iconProps} />;
      }
      case 'inbox': {
        return <InboxIcon {...iconProps} />;
      }
      case 'info': {
        return <InfoIcon {...iconProps} />;
      }
      case 'invoice-details': {
        return <InvoiceDetailsIcon {...iconProps} />;
      }
      case 'home': {
        return <HomeIcon {...iconProps} />;
      }
      case 'key': {
        return <KeyIcon {...iconProps} />;
      }
      case 'licenses': {
        return <LicensesIcon {...iconProps} />;
      }
      case 'licenses-add': {
        return <LicensesAddIcon {...iconProps} />;
      }
      case 'licenses-modeltree': {
        return <LicensesModelTreeIcon {...iconProps} />;
      }
      case 'licenses-fundy': {
        return <LicensesFundyIcon {...iconProps} />;
      }
      case 'link-2': {
        return <Link2Icon {...iconProps} />;
      }
      case 'link': {
        return <LinkIcon {...iconProps} />;
      }
      case 'lock': {
        return <LockIcon {...iconProps} />;
      }
      case 'logo-account': {
        return <LogoAccountIcon {...iconProps} />;
      }
      case 'logo-account-disabled': {
        return <LogoAccountIconDisabled {...iconProps} />;
      }
      case 'logo-administration': {
        return <LogoAdministrationIcon {...iconProps} />;
      }
      case 'logo-administration-disabled': {
        return <LogoAdministrationIconDisabled {...iconProps} />;
      }
      case 'logo-exquance': {
        return <LogoExquanceIcon {...iconProps} />;
      }
      case 'logo-exquance-disabled': {
        return <LogoExquanceIconDisabled {...iconProps} />;
      }
      case 'logo-fundy': {
        return <LogoFundyIcon {...iconProps} />;
      }
      case 'logo-fundy-disabled': {
        return <LogoFundyIconDisabled {...iconProps} />;
      }
      case 'logo-insights': {
        return <LogoInsightsIcon {...iconProps} />;
      }
      case 'logo-insights-disabled': {
        return <LogoInsightsIconDisabled {...iconProps} />;
      }
      case 'logo-model-tree': {
        return <LogoModelTreeIcon {...iconProps} />;
      }
      case 'logo-model-tree-disabled': {
        return <LogoModelTreeIconDisabled {...iconProps} />;
      }
      case 'logo-omni': {
        return <LogoOmniIcon {...iconProps} />;
      }
      case 'logo-omni-disabled': {
        return <LogoOmniIconDisabled {...iconProps} />;
      }
      case 'minus': {
        return <MinusIcon {...iconProps} />;
      }
      case 'more': {
        return <MoreIcon {...iconProps} />;
      }
      case 'paperclip': {
        return <PaperclipIcon {...iconProps} />;
      }
      case 'pause': {
        return <PauseIcon {...iconProps} />;
      }
      case 'play': {
        return <PlayIcon {...iconProps} />;
      }
      case 'plus': {
        return <PlusIcon {...iconProps} />;
      }
      case 'release': {
        return <ReleaseIcon {...iconProps} />;
      }
      case 'search': {
        return <SearchIcon {...iconProps} />;
      }
      case 'settings': {
        return <SettingsIcon {...iconProps} />;
      }
      case 'shield': {
        return <ShieldIcon {...iconProps} />;
      }
      case 'sign-in': {
        return <SignInIcon {...iconProps} />;
      }
      case 'sign-out': {
        return <SignOutIcon {...iconProps} />;
      }
      case 'spinner': {
        return <SpinnerIcon {...iconProps} />;
      }
      case 'sort-less': {
        return <SortLessIcon {...iconProps} />;
      }
      case 'sort-more': {
        return <SortMoreIcon {...iconProps} />;
      }
      case 'status': {
        return <StatusIcon {...iconProps} />;
      }
      case 'success': {
        return <SuccessIcon {...iconProps} />;
      }
      case 'switcher': {
        return <SwitcherIcon {...iconProps} />;
      }
      case 'tool': {
        return <ToolIcon {...iconProps} />;
      }
      case 'trash': {
        return <TrashIcon {...iconProps} />;
      }
      case 'unlock': {
        return <UnlockIcon {...iconProps} />;
      }
      case 'update': {
        return <UpdateIcon {...iconProps} />;
      }
      case 'upload': {
        return <UploadIcon {...iconProps} />;
      }
      case 'user': {
        return <UserIcon {...iconProps} />;
      }
      case 'user-check': {
        return <UserCheckIcon {...iconProps} />;
      }
      case 'user-minus': {
        return <UserMinusIcon {...iconProps} />;
      }
      case 'user-plus': {
        return <UserPlusIcon {...iconProps} />;
      }
      case 'users': {
        return <UsersIcon {...iconProps} />;
      }
      case 'zap': {
        return <ZapIcon {...iconProps} />;
      }
      case 'repeat': {
        return <RepeatIcon {...iconProps} />;
      }
      case 'percent': {
        return <PercentIcon {...iconProps} />;
      }
      case 'number': {
        return <NumberIcon {...iconProps} />;
      }
      case 'send': {
        return <SendIcon {...iconProps} />;
      }
      case 'map-filled': {
        return <MapIconFilled {...iconProps} />;
      }
      case 'map': {
        return <MapIcon {...iconProps} />;
      }
      case 'minimize-map': {
        return <MinimizeMapIcon {...iconProps} />;
      }

      case 'slider': {
        return <SliderIcon {...iconProps} />;
      }

      case 'break': {
        return <BreakIcon {...iconProps} />;
      }
      case 'visual-tour-chevron-left': {
        return <VisualTourChevronLeftIcon {...iconProps} />;
      }
      case 'visual-tour-chevron-right': {
        return <VisualTourChevronRightIcon {...iconProps} />;
      }
    }
  };

  return (
    <div className={classNames(className, styles.icon)} style={style} id={id}>
      {getIconByName()}
    </div>
  );
};

Icon.displayName = 'Icon';
