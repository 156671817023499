export const formatDateToUTCMidnight = (date: Date): Date => {
  if (!date) {
    return null;
  }

  const updatedDate = new Date(date.toUTCString());

  const timezoneOffset = date.getTimezoneOffset();

  const offsetHours = Math.floor(timezoneOffset / 60);
  const offsetMinutes = timezoneOffset % 60;

  updatedDate.setHours(-1 * offsetHours, -1 * offsetMinutes, 0, 0);
  return updatedDate;
};
