import React, { Fragment } from 'react';
import {
  colors,
  common,
  Dropdown,
  formatDate,
  formatNumber,
  Icon,
  offsets,
  TableColumn,
  Tooltip,
  Typography
} from '@xq/ui-kit';
import styles from './Organizations.module.scss';
import {
  ManagerList,
  OrganizationDataItem
} from '@pages/Organizations/Organizations/dataTypes';
import { TFunction } from 'i18next';
import type {
  OrganizationLicenseInfoDTOResponse,
  OrganizationModeltreeInfoDTOResponse
} from '@xq/omni-gateway-frontend-client';
import { DASH } from '@constants';

export function createOrganizationsRows(
  organizations: OrganizationDataItem[],
  managers: ManagerList[],
  changeManager: (rowId: string, newManagerValue: string) => void
) {
  return organizations?.map((row) => ({
    id: row.uuid,
    data: {
      name: (
        <Fragment>
          <Typography
            element="div"
            variant="body-5"
            className={common['no-text-transform']}
          >
            {row.name}
          </Typography>
          {row.description && (
            <Typography
              element="div"
              variant="body-6"
              className={offsets['mt-2']}
            >
              {row.description}
            </Typography>
          )}
        </Fragment>
      ),
      countryIso3: row.countryIso3,
      createdAt: formatDate(row.createdAt),
      licenses: (
        <div className={styles['license-rows']}>
          {row.licenses?.map((license, index) => (
            <div key={index} className={styles['license-row']}>
              {showWarningForLicense(license) && (
                //todo create message
                <Tooltip
                  className={styles['warning-icon']}
                  type="bottom"
                  content={'expired'}
                >
                  <Icon color={'warning'} name={'alert'} size={'s'} />
                </Tooltip>
              )}
              <Typography element="div" key={index} variant="body-4">
                {license.name}
              </Typography>
            </div>
          ))}
        </div>
      ),
      users: (
        <Typography variant="number-1">
          {formatNumber(row.usersAmount)}
        </Typography>
      ),
      // todo show we will have mtDatabaseVersion
      mtDatabaseVersion: (
        <div className={styles['db-row']}>
          {showWarningForDatabase(row.modeltreeInfo) && (
            //todo create message
            <Tooltip
              className={styles['warning-icon']}
              type="bottom"
              content={"Client's ModelTree need to be updated"}
            >
              <Icon color={'warning'} name={'alert'} size={'s'}></Icon>
            </Tooltip>
          )}
          <Typography
            variant="number-1"
            color={
              showWarningForDatabase(row.modeltreeInfo)
                ? colors.goldColor
                : colors.gray100Color
            }
          >
            {row?.modeltreeInfo?.currentAppVersion || DASH}
          </Typography>
          <Typography variant="number-1" className={offsets['mx-8']}>
            /
          </Typography>
          <Typography variant="number-1">
            {row?.modeltreeInfo?.assignedAppVersion || DASH}
          </Typography>
        </div>
      ),
      managerUuid: (
        <>
          {managers?.length > 0 && (
            <Dropdown
              onValueSelected={(value) => changeManager(row.uuid, value)}
              items={managers}
              selectedItemValue={row.managerUuid}
              variant="simple"
              position="bottom"
            />
          )}
        </>
      )
    }
  }));
}

export const createOrganizationsColumns = (t: TFunction): TableColumn[] => [
  {
    title: t('common.name'),
    key: 'name',
    width: 140,
    alignment: 'left',
    paddingLeft: 20,
    sortable: true
  },
  {
    title: t('common.country'),
    key: 'countryIso3',
    width: 50,
    alignment: 'left',
    paddingLeft: 20,
    paddingRight: 20,
    sortable: true
  },
  {
    title: t('common.createOn'),
    key: 'createdAt',
    width: 100,
    alignment: 'left',
    paddingLeft: 20,
    paddingRight: 20,
    sortable: true
  },
  {
    title: t('common.licenses'),
    key: 'licenses',
    width: 160,
    alignment: 'left',
    paddingLeft: 40,
    sortable: false
  },
  {
    title: t('common.users'),
    key: 'users',
    width: 90,
    alignment: 'right',
    paddingLeft: 20,
    paddingRight: 20,
    sortable: false
  },
  {
    title: t('organizations.mtVersionDatabaseAllowed'),
    key: 'mtDatabaseVersion',
    width: 170,
    alignment: 'right',
    paddingLeft: 20,
    paddingRight: 20,
    sortable: false
  },
  {
    title: t('common.organizationManager'),
    key: 'managerUuid',
    width: 160,
    alignment: 'left',
    paddingLeft: 20,
    sortable: true
  }
];

const showWarningForLicense = (
  license: OrganizationLicenseInfoDTOResponse
): boolean => {
  return license.isExpired;
};

const showWarningForDatabase = (
  modeltreeInfo: OrganizationModeltreeInfoDTOResponse
): boolean => {
  if (!modeltreeInfo?.currentAppVersion || !modeltreeInfo?.assignedAppVersion) {
    return false;
  }
  return !(
    modeltreeInfo?.currentAppVersion == modeltreeInfo?.assignedAppVersion
  );
};
