import React, {
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import styles from './License.module.scss';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams, useSearchParams } from 'react-router-dom';
import {
  Alert,
  Breadcrumbs,
  BreadcrumbsItem,
  changeSortDirection,
  Col,
  common,
  Filter,
  offsets,
  openStatusNotification,
  Row,
  Search,
  SelectItem,
  SkeletonPlaceholder,
  SM,
  SortDirection,
  Table,
  TableAction,
  TableColumn,
  TableFilter,
  TableFilterGroup,
  TableRow,
  Typography,
  useCurrentWidth,
  XS
} from '@xq/ui-kit';
import { LicenseService, LicenseServiceApi } from './license-service';
import { useDebounce } from '@hooks';
import { config } from '@config';
import {
  checkNoMoreData,
  debounce,
  getBreadcrumbWithDropdown,
  getStatusNotificationTranslations,
  mapFiltersFromUIToGateway
} from '@services';
import { SidemenuContext, SidemenuContextData } from '@context';
import { getRouteUrl, ROUTES } from '@router';
import { ListMetaInfo, ListParams, TableFilterValues } from 'interfaces';
import { SORT_DIRECTIONS } from '@constants';
import { LicensePageData, LicenseUser } from './dataTypes';
import {
  createActiveUserColumns,
  createActiveUserRows,
  createInactiveUserColumns,
  createInactiveUserRows,
  mapColumnKey
} from './utils';
import {
  LicenseInformationChevrons,
  LicenseSystemInfographics,
  LicenseUserTrackingReport
} from './components';
import { LicenseInformationButtons } from './components/LicenseInformationButtons/LicenseInformationButtons';
import cn from 'classnames';

export const License: FC = () => {
  const service: LicenseService = new LicenseServiceApi();
  const { t } = useTranslation();

  const params = useParams();
  const sidemenuContext: SidemenuContextData = useContext(SidemenuContext);
  const [searchParams, setSearchParams] = useSearchParams();

  /** Tables and base information  */
  const [activeUsersMeta, setActiveUsersMeta] = useState<ListMetaInfo>(null);
  const [inactiveUsersMeta, setInactiveUsersMeta] =
    useState<ListMetaInfo>(null);
  const [information, setInformation] = useState<LicensePageData>(null);
  const [activeUsers, setActiveUsers] = useState<LicenseUser[]>([]);
  const [inactiveUsers, setInactiveUsers] = useState<LicenseUser[]>([]);

  const [searchValue, setSearchValue] = useState<string>(
    searchParams.get('search') || ''
  );
  const debouncedSearchValue = useDebounce(searchValue, config.debounceDelay);
  const [isMainInfoLoading, setIsMainInfoLoading] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [noActiveUsersResults, setNoActiveUsersResults] =
    useState<boolean>(false);
  const [noInactiveUsersResults, setNoInactiveUsersResults] =
    useState<boolean>(false);
  const [sortOrder, setSortOrder] = useState<SortDirection>(
    SORT_DIRECTIONS.ASC as SortDirection
  );
  const [sortBy, setSortBy] = useState<string>('fullname');
  const [noMoreActiveUsersData, setNoMoreActiveUsersData] =
    useState<boolean>(false);
  const [noMoreInactiveUsersData, setNoMoreInactiveUsersData] =
    useState<boolean>(false);

  const [filters, setFilters] = useState<TableFilter[]>([]);
  const [filterGroups, setFilterGroups] = useState<TableFilterGroup[]>([]);

  /** values to alert */
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  const [submitText, setSubmitText] = useState<string>('');
  const [alertText, setAlertText] = useState<string>('');
  const [alertSubmitFunction, setAlertSubmitFunction] = useState<() => void>();

  const activeUserColumns: TableColumn[] = createActiveUserColumns(t);
  const inactiveUserColumns: TableColumn[] = createInactiveUserColumns(t);

  const activeUserActions: TableAction[] = [
    {
      key: 'delete',
      title: t('common.delete'),
      icon: 'trash',
      onClick: detachUserSubmit
    }
  ];

  const inactiveUserActions: TableAction[] = [
    {
      key: 'add',
      title: t('common.add'),
      icon: 'plus',
      onClick: attachUser
    }
  ];

  const activeUserRows = useMemo(() => {
    return createActiveUserRows(t, activeUsers, information?.roles, changeRole);
  }, [activeUsers, information?.roles]);

  const inactiveUserRows = useMemo(() => {
    return createInactiveUserRows(t, inactiveUsers);
  }, [inactiveUsers]);

  function detachUserSubmit(user: TableRow) {
    const userInfo = activeUsers.find((el) => el.uuid === user.id);
    setAlertText(
      `${t('alerts.areYouSureYouWantToDetach')} <b>${userInfo?.email}</b>?`
    );
    setSubmitText(t('organizations.detach'));
    setAlertSubmitFunction(() => () => detachUser(user));
    setIsAlertOpen(true);
  }

  async function detachUser(userRow: TableRow) {
    try {
      await service.detachUser(params.id, params?.licenseId, userRow.id);

      const user = activeUsers.find((el) => userRow.id === el.uuid);
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200,
        message: (
          <span>
            <strong>{user.email}</strong>{' '}
            {t('notifications.isSuccessfullyDetachedFromLicense')}
          </span>
        )
      });

      setActiveUsers(activeUsers.filter((el) => userRow.id !== el.uuid));
      setInactiveUsers([user, ...inactiveUsers]);

      setInformation({
        ...information,
        activeUsers: user?.isBlocked
          ? information.activeUsers
          : --information.activeUsers
      });
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  async function attachUser(userRow: TableRow) {
    try {
      const updatedUser = await service.attachUser(
        params.id,
        params?.licenseId,
        userRow.id
      );
      const user = inactiveUsers.find((el) => el.uuid === userRow.id);
      user.attachDate = updatedUser?.attachDate;
      user.role = updatedUser?.role;

      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200,
        message: (
          <span>
            <strong>{user.email}</strong>{' '}
            {t('notifications.isSuccessfullyAttachedToLicense')}
          </span>
        )
      });

      setActiveUsers([user, ...activeUsers]);
      setInactiveUsers(inactiveUsers.filter((el) => el.uuid !== userRow.id));

      setInformation({
        ...information,
        activeUsers: ++information.activeUsers
      });
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  const getQueryParams = (page: number): ListParams => {
    return {
      searchValue: String(debouncedSearchValue),
      sortOrder: sortOrder,
      sortBy: mapColumnKey(sortBy),
      page: page,
      filters: mapFiltersFromUIToGateway(filters)
    };
  };

  async function fetchActiveUsers() {
    const queryParams = getQueryParams(1);
    try {
      setIsLoading(true);
      const response = await service.fetchActiveUsers(
        params?.id,
        params?.licenseId,
        queryParams
      );
      const hasResults = !response.items || response?.items?.length === 0;
      setNoActiveUsersResults(hasResults);
      setActiveUsers(response?.items);
      checkNoMoreData(
        response?.items?.length,
        response?.meta?.totalItems,
        setNoMoreActiveUsersData
      );
      setActiveUsersMeta(response?.meta);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchInactiveUsers() {
    const queryParams = getQueryParams(1);
    try {
      setIsLoading(true);
      const response = await service.fetchInactiveUsers(
        params?.id,
        params?.licenseId,
        queryParams
      );

      const hasResults = !response.items || response?.items?.length === 0;
      setNoInactiveUsersResults(hasResults);
      setInactiveUsers(response?.items);
      checkNoMoreData(
        response?.items?.length,
        response?.meta?.totalItems,
        setNoMoreInactiveUsersData
      );
      setInactiveUsersMeta(response?.meta);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchData() {
    try {
      setIsMainInfoLoading(true);
      const response = await service.fetchData(params?.id, params.licenseId);
      setInformation(response);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsMainInfoLoading(false);
    }
  }

  async function fetchFilters() {
    try {
      const filterValues: TableFilterValues = await service.fetchFilters(
        params?.id,
        params?.licenseId
      );
      setFilters(filterValues.filters);
      setFilterGroups(filterValues.groups);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  async function changeRole(rowId: string, newRoleValue: string) {
    try {
      await service.changeUserRole(
        params?.id,
        params?.licenseId,
        rowId,
        newRoleValue
      );

      const user = activeUsers.find((el) => el.uuid === rowId);
      const newRole: SelectItem = information?.roles?.find(
        (role) => role.value === newRoleValue
      );

      const updatedActiveUsers: LicenseUser[] = activeUsers.map((user) => {
        if (user.uuid === rowId) {
          return { ...user, role: newRole };
        }
        return user;
      });

      setActiveUsers(updatedActiveUsers);

      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200,
        message: (
          <div>
            {t('notifications.roleIsSuccessfullyChangedForUser')}{' '}
            <strong>{user.email}</strong>.{' '}
            <div>
              {t('uiKit.newRole')}: <strong>{newRole.label}</strong>
            </div>
          </div>
        )
      });
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  function updateSortParams(key: string) {
    let updatedSortBy = sortBy;
    let updatedSortOrder = sortOrder;

    if (key === sortBy) {
      updatedSortOrder = changeSortDirection(sortOrder);
      setSortOrder(updatedSortOrder);
    } else {
      updatedSortBy = key;
      setSortBy(updatedSortBy);
    }
    return { updatedSortBy: updatedSortBy, updatedSortOrder: updatedSortOrder };
  }

  async function loadMoreActiveUsers() {
    try {
      if (noMoreActiveUsersData) {
        return;
      }
      const queryParams = getQueryParams(++activeUsersMeta.currentPage);

      const response = await service.fetchActiveUsers(
        params.id,
        params.licenseId,
        queryParams
      );

      const { items } = response;
      checkNoMoreData(
        items?.length,
        response?.meta?.totalItems,
        setNoMoreActiveUsersData
      );
      setActiveUsers([...activeUsers, ...items]);
      setActiveUsersMeta(response?.meta);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  async function loadMoreInactiveUsers() {
    try {
      if (noMoreInactiveUsersData) {
        return;
      }

      const queryParams = getQueryParams(++inactiveUsersMeta.currentPage);

      const response = await service.fetchInactiveUsers(
        params.id,
        params.licenseId,
        queryParams
      );

      const { items } = response;
      checkNoMoreData(
        items?.length,
        response?.meta?.totalItems,
        setNoMoreInactiveUsersData
      );
      setInactiveUsers([...inactiveUsers, ...items]);
      setInactiveUsersMeta(response?.meta);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  const updateSearchParams = useCallback(
    debounce((newValue) => {
      if (newValue) {
        searchParams.set('search', newValue);
      } else {
        searchParams.delete('search');
      }
      setSearchParams(searchParams);
    }, config.debounceDelay),
    [searchParams, setSearchParams]
  );

  useEffect(() => {
    const searchParamValue = searchParams.get('search') || '';
    setSearchValue(searchParamValue);
  }, [searchParams]);

  const handleSearchValue = (newValue: string) => {
    setSearchValue(newValue);
    updateSearchParams(newValue);
  };

  useEffect(() => {
    if (inactiveUsers?.length === 0) {
      setNoMoreInactiveUsersData(true);
    }
  }, [inactiveUsers]);

  useEffect(() => {
    fetchData();
  }, [params?.licenseId, params?.id]);

  useEffect(() => {
    fetchFilters();
  }, []);

  useEffect(() => {
    fetchActiveUsers();
    fetchInactiveUsers();
  }, [
    params?.licenseId,
    params?.id,
    sortOrder,
    sortBy,
    debouncedSearchValue,
    filters
  ]);

  /** Sidemenu */
  useEffect(() => {
    sidemenuContext.setActiveMenu(
      `licenses-${sidemenuContext?.licenses?.find(
        (el) => el?.uuid === params.licenseId
      )?.uuid}`
    );
  }, [sidemenuContext.licenses, params?.licenseId]);

  /** Breadcrumbs */
  const breadcrumbs: BreadcrumbsItem[] = useMemo(
    () => [
      {
        label: t(ROUTES.ORGANIZATIONS.MAIN),
        url: getRouteUrl(ROUTES.ORGANIZATIONS.MAIN)
      },
      getBreadcrumbWithDropdown(
        t,
        sidemenuContext,
        ROUTES.ORGANIZATIONS.LICENSES,
        { id: params.id }
      ),
      {
        label: t(ROUTES.ORGANIZATIONS.LICENSES),
        url: getRouteUrl(ROUTES.ORGANIZATIONS.LICENSES, { id: params.id })
      }
    ],
    [
      sidemenuContext.organizations,
      sidemenuContext.currentOrganization,
      params.id
    ]
  );

  /** Breakpoint */
  const { breakpoint } = useCurrentWidth();

  const isMD = useMemo(() => {
    //todo replace it to ui kit
    return breakpoint !== XS && breakpoint !== SM;
  }, [breakpoint]);

  return (
    <Fragment>
      <Row cols={10}>
        <Breadcrumbs
          NavLink={NavLink}
          className={'breadcrumbs'}
          items={breadcrumbs}
        />
        <Col col={9} className={styles.heading}>
          <Fragment>
            {isMainInfoLoading ? (
              <SkeletonPlaceholder width={200} height={36} />
            ) : (
              <Typography element="div" variant="h2">
                {information?.title}
              </Typography>
            )}
          </Fragment>

          {information && (
            <LicenseInformationButtons
              information={information}
              setInformation={setInformation}
              isMD={isMD}
            />
          )}
        </Col>

        <LicenseInformationChevrons information={information} />
      </Row>

      <Row cols={10} style={{ marginRight: 0 }}>
        <LicenseSystemInfographics
          information={information}
          isLoading={isMainInfoLoading}
          isMD={isMD}
        />
      </Row>

      <Row cols={10}>
        <Col
          col={9}
          className={cn(common['container-queries-wrap'], offsets['mb-20'])}
        >
          <div className={common['table-filters-wrap']}>
            <Search
              className={common['table-search']}
              placeholder={t('common.searchByName')}
              value={searchValue}
              onChange={handleSearchValue}
            />
            {filters?.length > 0 && (
              <Filter
                className={common['table-filter']}
                filters={filters}
                setFilters={setFilters}
                filterGroups={filterGroups}
              />
            )}
          </div>
        </Col>

        <Col col={9}>
          <div className={styles['table-grid']}>
            <div className={styles.table}>
              <Table
                isLoading={isLoading}
                className={offsets['mb-40']}
                rows={activeUserRows}
                columns={activeUserColumns}
                actions={activeUserActions}
                noMoreData={noMoreActiveUsersData}
                noMoreItemsText={t('common.noMoreItems')}
                onLoading={loadMoreActiveUsers}
                maxHeight="600px"
                minHeight="0"
                onSort={updateSortParams}
                sortOrder={sortOrder}
                sortBy={sortBy}
                noResults={noActiveUsersResults}
                noResultsText={t('common.noResults')}
              />

              {inactiveUsers && inactiveUsers.length > 0 && (
                <Fragment>
                  <Typography variant={'h5'} className={offsets['mb-30']}>
                    {t('organizations.attachUsers')}
                  </Typography>
                  <Table
                    isLoading={isLoading}
                    id="inactive-users"
                    rows={inactiveUserRows}
                    columns={inactiveUserColumns}
                    actions={inactiveUserActions}
                    noMoreData={noMoreInactiveUsersData}
                    noMoreItemsText={t('common.noMoreItems')}
                    onLoading={loadMoreInactiveUsers}
                    maxHeight="600px"
                    minHeight="0"
                    noResults={noInactiveUsersResults}
                    noResultsText={t('common.noResults')}
                    showHeader={false}
                  />
                </Fragment>
              )}
            </div>

            <div className={styles.card}>
              <LicenseUserTrackingReport />
            </div>
          </div>
        </Col>
      </Row>

      <Alert
        isOpen={isAlertOpen}
        onSubmit={alertSubmitFunction}
        onClose={() => setIsAlertOpen(false)}
        cancelText={t('common.cancel')}
        submitText={submitText}
      >
        <div dangerouslySetInnerHTML={{ __html: alertText }} />
      </Alert>
    </Fragment>
  );
};

License.displayName = 'License';
