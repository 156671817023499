import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { SkeletonPlaceholder } from '@components';

import styles from '@components/Charts/TableChart/TableChart.module.scss';

function ChartLoader({ className }: { className: string }): ReactElement {
  return (
    <div className={classNames(styles.chartLoaderWrapper, className)}>
      <div className={styles.chartLoaderWrapperHeader}>
        <div className={styles.chartLoaderWrapperHeaderLeft}>
          <SkeletonPlaceholder width={200} height={24} />
          <SkeletonPlaceholder width={40} height={10} />
        </div>
        <div className={styles.chartLoaderWrapperHeaderRight}>
          <SkeletonPlaceholder width={80} height={12} />
          <SkeletonPlaceholder width={140} height={36} />
          <SkeletonPlaceholder width={50} height={12} />
        </div>
      </div>
      <div className={styles.chartLoaderWrapperMain}>
        <SkeletonPlaceholder width={'100%'} height={140} />
      </div>
      <div className={styles.chartLoaderWrapperFooter}>
        <SkeletonPlaceholder width={'100%'} height={16} />
      </div>
    </div>
  );
}

export default ChartLoader;
