import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import {
  Breadcrumbs,
  BreadcrumbsItem,
  Button,
  Col,
  DateIntervalType,
  DatePicker,
  downloadFileByLink,
  Input,
  offsets,
  openStatusNotification,
  Row,
  SavedFileName,
  Select,
  SelectItem,
  Textarea,
  Typography,
  Uploader
} from '@xq/ui-kit';
import styles from './EditOrganization.module.scss';
import { useTranslation } from 'react-i18next';
import {
  EditOrganizationService,
  EditOrganizationServiceApi
} from './edit-organization-service';
import cn from 'classnames';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { SidemenuContext, SidemenuContextData } from '@context';
import {
  getBreadcrumbWithDropdown,
  getCurrentLanguage,
  getStatusNotificationTranslations,
  ORGANIZATION_SIDEMENUS,
  submitForm
} from '@services';
import { getRouteUrl, ROUTES } from '@router';
import {
  FileInfoDTOResponse,
  FileRestrictionsDTOResponse,
  OrganizationEditDTORequest
} from '@xq/omni-gateway-frontend-client';

export const EditOrganization: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const sidemenuContext: SidemenuContextData = useContext(SidemenuContext);
  const service: EditOrganizationService = new EditOrganizationServiceApi();

  const [managers, setManagers] = useState<SelectItem[]>(null);
  const [countries, setCountries] = useState<SelectItem[]>(null);
  const [name, setName] = useState<string | number>('');
  const [description, setDescription] = useState<string>('');
  const [agreementNumber, setAgreementNumber] = useState<string | number>('');
  const [manager, setManager] = useState(null);
  const [currency, setCurrency] = useState<SelectItem>(null);
  const [country, setCountry] = useState<SelectItem>(null);
  /* New uploaded files */
  const [files, setFiles] = useState<File[]>([]);
  /* File names from backend */
  const [fileNames, setFileNames] = useState<SavedFileName[]>([]);
  /* File names from backend should be deleted */
  const [deleteAgreementFiles, setDeleteAgreementFiles] = useState<string[]>(
    []
  );
  const [agreementDate, setAgreementDate] = useState<Date>(null);
  const [agreementFilesRestrictions, setAgreementFilesRestrictions] =
    useState<FileRestrictionsDTOResponse>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reset, setReset] = useState<boolean>(false);

  async function fetchData() {
    try {
      const response = await service.fetchData(params.id);
      setManagers(response?.managers);
      setCountries(response?.countries);
      setName(response?.name);
      setDescription(response?.description);
      setAgreementNumber(response?.agreementId);
      setCurrency(response?.currency);
      setCountry(response?.country);
      setManager(response?.manager);
      setAgreementDate(response?.agreementDate);
      setFileNames(response?.agreementFiles);
      setAgreementFilesRestrictions(response?.agreementFilesRestrictions);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  const isFieldsFilled = (): boolean => {
    return !(name && currency && country);
  };

  async function update() {
    try {
      setReset(true);

      const organization: OrganizationEditDTORequest = {
        name: name ? String(name) : null,
        description: description ? String(description) : null,
        managerUuid: manager?.value || null,
        countryIso3: country?.value || null,
        currencyIso3: currency?.value || null,
        agreementDate: agreementDate || null,
        agreementId: agreementNumber ? String(agreementNumber) : null
      };

      if (deleteAgreementFiles) {
        organization.deleteAgreementFiles = deleteAgreementFiles;
      }

      setIsLoading(true);

      await service.update(params.id, organization);

      if (files?.length > 0) {
        await service.addOrganizationAgreementFiles(params.id, files);
        openStatusNotification({
          translations: getStatusNotificationTranslations(t),
          status: 200
        });
      } else {
        openStatusNotification({
          translations: getStatusNotificationTranslations(t),
          status: 200
        });
      }
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function getAgreementFile(
    fileName: FileInfoDTOResponse
  ): Promise<void> {
    try {
      const url = await service.getAgreementFile(params.id, fileName.name);
      await downloadFileByLink(url);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  const onDeleteBackendFile = (file: FileInfoDTOResponse) => {
    /* Add to delete list */
    setDeleteAgreementFiles([...deleteAgreementFiles, file.name]);
    /* Remove from file names */
    const updatedFileNames = fileNames?.filter(
      (fileName) => file.name !== fileName.name
    );
    setFileNames(updatedFileNames);
  };

  function cancel() {
    navigate(getRouteUrl(ROUTES.ORGANIZATIONS.MAIN));
  }

  const breadcrumbs: BreadcrumbsItem[] = useMemo(
    () => [
      {
        label: t(ROUTES.ORGANIZATIONS.MAIN),
        url: getRouteUrl(ROUTES.ORGANIZATIONS.MAIN)
      },
      getBreadcrumbWithDropdown(
        t,
        sidemenuContext,
        ROUTES.ORGANIZATIONS.ORGANIZATION_INFO,
        { id: params?.id }
      )
    ],
    [sidemenuContext, params]
  );

  useEffect(() => {
    fetchData();
    sidemenuContext.setActiveMenu(ORGANIZATION_SIDEMENUS.ORGANIZATION_INFO);
  }, [params]);

  return (
    <div>
      <Row cols={10}>
        <Col col={10}>
          <Breadcrumbs
            NavLink={NavLink}
            className={'breadcrumbs'}
            items={breadcrumbs}
          />
          <Typography className={offsets['mb-40']} element="div" variant="h2">
            {t('organizations.organizationInfo')}
          </Typography>
        </Col>
      </Row>

      <Row cols={10}>
        <Col col={8} md={4}>
          <form onSubmit={submitForm}>
            <Input
              disabled={isLoading}
              value={name}
              className={offsets['mb-20']}
              label={t('common.name')}
              onChange={setName}
            />
            <Textarea
              disabled={isLoading}
              value={description}
              onChange={setDescription}
              className={offsets['mb-40']}
              label={t('common.description')}
            />

            <div className={cn(offsets['mb-40'], styles.block)}>
              <Select
                disabled={isLoading}
                items={countries}
                selected={country}
                onChange={setCountry}
                label={t('common.country')}
              />
              <Select
                disabled
                items={[]}
                selected={currency}
                onChange={setCurrency}
                label={t('common.currency')}
              />
            </div>
            <Select
              className={offsets['mb-40']}
              items={managers}
              selected={manager}
              disabled={isLoading}
              onChange={setManager}
              label={t('common.organizationManager')}
            />
            <div className={cn(offsets['mb-40'], styles.block)}>
              <Input
                value={agreementNumber}
                disabled={isLoading}
                onChange={setAgreementNumber}
                label={t('organizations.agreementNumber')}
              />
              <DatePicker
                datePickerPlaceholder={t('organizations.agreementDate')}
                type="day"
                dateIntervalType={DateIntervalType.Date}
                locale={getCurrentLanguage()}
                datePickerValue={agreementDate}
                disabled={isLoading}
                onDatePickerChange={setAgreementDate}
              />
            </div>

            {/*todo return blob in onSaveBackendFile */}
            <Uploader
              className={offsets['mb-40']}
              files={files}
              upload={setFiles}
              savedFileNames={fileNames}
              onDeleteBackendFile={onDeleteBackendFile}
              onSaveBackendFile={getAgreementFile}
              reset={reset}
              setReset={setReset}
              disabled={isLoading}
              maxSize={agreementFilesRestrictions?.maxByteSize}
              accept={agreementFilesRestrictions?.allowedMimeTypes}
              maximumUploadFileSizeText={t('common.maximumUploadFileSizeText')}
              dragFileHereOrText={t('common.dragFileHereOr')}
              browseText={t('common.browse')}
              loadingFailedText={t('uiKit.loadingFailedText')}
            />

            <div>
              <Button
                buttonType={'submit'}
                onClick={update}
                isLoading={isLoading}
                disabled={isFieldsFilled()}
                className={offsets['mr-20']}
              >
                {t('common.save')}
              </Button>
              <Button onClick={cancel} disabled={isLoading} type="secondary">
                {t('common.cancel')}
              </Button>
            </div>
          </form>
        </Col>
      </Row>
    </div>
  );
};

EditOrganization.displayName = 'EditOrganization';
